export default {
    "videoMainView_unit": "Vehículo",
    "videoMainView_driver": "Conductor",
    "videoMainView_event": "Evento",
    "videoMainView_address": "Dirección",
    "videoMainView_dateTime": "Fecha/Hora",
    "videoMainView_speed": "Velocidad",
    "videoMainView_notes": "Notas",
    "videoMainView_event_id": "Id del Evento",
    "videoMainView_when": "Cuando",
    "videoMainView_videoEvents": "Video: Eventos",
    "videoMainView_filters": "Filtros:",
    "videoMainView_units": "Etiquetas/Unidades/Conductores:",
    "videoMainView_events": "Eventos",
    "videoMainView_openSearch": "Busqueda: ",
    "videoMainView_dateRange": "Rango de Fechas:",
    "videoMainView_hasNotes": "Con Notas",
    "videoMainView_dates_today": 'Hoy',
    "videoMainView_dates_yesterday": 'Ayer',
    "videoMainView_dates_week": 'Esta semana',
    "videoMainView_dates_lastWeek": 'Semana anterior',
    "videoMainView_dates_quarter": 'Este trimestre',
    "videoMainView_dates_lastQuarter": 'El trimestre pasado',
    "videoMainView_dates_custom": 'Personalizado',
    "videoMainView_dates_thisMonth": 'Este Mes',
    "videoMainView_dates_lastMonth": 'El mes pasado',
    "videoMainView_dates_last24hours": 'Últimas 24 Horas',
    "videoMainView_dates_last7days": 'Últimos 7 Dias',
    "videoMainView_dates_last15days": 'Últimos 15 Dias',
    "videoMainView_dates_last30Days": 'Últimos 30 Dias',
    "videoMainView_dates_last90Days": 'Últimos 90 Dias',
    "videoMainView_dates_last3month": 'Últimos 3 Meses',
    "videoMainView_addNote": 'Agregar nota...',
    "videoMainView_alertMessage": 'Los videos expiran luego de 90 días',
    "videoMainView_download360Error": "Los videos 360 no se pueden descargar en el momento",
    "videoMainView_eventId": "Id",
    "videoMainView_tags": "Etiquetas",
    "videoMainView_changeStatus": "Cambiar Estado",
    "videoMainView_eventsSelected": "eventos seleccionados",
};
