export default {
  settingsDriver_search: "Search by Driver or ID",
  settingsDriver_driver: "Driver",
  settingsDriver_name: "Name",
  settingsDriver_vehicle: "Vehicle",
  settingsDriver_timezone: "Timezone",
  settingsDriver_contact: "Contact",
  settingsDriver_contactDetails: "Contact Details",
  settingsDriver_cellphone: "Cell phone",
  settingsDriver_carrier: "Carrier",
  settingsDriver_address: "Address",
  settingsDriver_username: "Username",
  settingsDriver_password: "Password",
  settingsDriver_defaultTab: "Default Tab",
  settingsDriver_create: "Create new Driver",
  settingsDriver_edit: "Edit Driver",
  settingsDriver_delete: "Delete Driver",
  settingsDriver_created: "Driver Created",
  settingsDriver_updated: "Driver Updated",
  settingsDriver_deleted: "Driver Deleted",
  settingsDriver_fsmLogin: "App Username",
  settingsDriver_addFsmLogin: "Edit to Create",
  settingsDriver_minLengthMessageName: "The username must contain a minimum of characters:",
  settingsDriver_maxLengthMessageName: "The username must contain a maximum of characters:",
  settingsDriver_minLengthMessagePassword: "The password must contain a minimum of characters:",
  settingsDriver_maxLengthMessagePassword: "The password must contain a maximum of characters:",
  settingsDriver_maxLengthMessageCellPhone: "The cell phone must contain a maximum of characters:",
  settingsDriver_minLengthMessageCellPhone: "The cell phone must contain a minimum of characters:",
  settingsDriver_addressMessage: "The address is incorrect",
  settingsDriver_descriptonDelete: "Are you sure you want to delete the Drivers?",
  settingsDriver_fmsLoginMessage: "To create or edit the FSM login it is necessary to add the 2 fields below:",
  settingsDriver_fmsLoginPwdRequired: 'The password is required',
  settingsDriver_import: "Driver uploads",
  settingsDriver_import_driver: "Import Drivers",
  settingsDriver_descriptonImport: "Import CSV File",
  settingsDriver_delimiter: "Delimiter",
  settingsDriver_file: "File",
  settingsDriver_upload: "Upload",
  settingsDriver_error_delimiter: "Error in file, please review the delimiter",
  settingsDriver_error_name_required: "Driver name and Tag is required",
  settingsDriver_error_name_repeat: "Driver name already exists",
  settingsDriver_error_name_repeat_file: "Driver name or Email is repeated",
  settingsDriver_error_fix: "Please fix errors before continuing",
  settingsDriver_error_started: " The import has been started, you will be notified when completed",
  settingsDriver_error_required: "Required File CSV",
  settingsDriver_template: "Driver Template",
  settingsDriver_aceept_file: "Only accept csv files",
  settingsDriver_email: 'Email',
  settingsDriver_group: 'Tag',
  settingsDriver_error: 'Error',
  settingsDriver_semicolon: 'Semicolon',
  settingsDriver_comma: 'Comma',
  settingsDriver_resume:'Transaction resume',
  settingsDriver_importation_title:'Import finished',
  settingsDriver_importation_body:'To see the summary click here',
  settingsDriver_status:'Status',
  settingsDriver_tag:'Tag',

  settingsDriver_filterBy:'Filter by',
  settingsDriver_clearFilters:'Clear filters',

  settingsDriver_all: "All",
  settingsDriver_tags: "Tags",
  settingsDriver_units: "Units",
  settingsDriver_unassigned: "Unassigned",
  settingsDriver_drivers: "Drivers",
  settingsDriver_driversFaces: "Drivers Faces",
  settingsDriver_driversFacesAssign: "Assign Driver",
  settingsDriver_driversFacesAll: "All",
  settingsDriver_driversFacesLinked: "Linked",
  settingsDriver_driversFacesUnlinked: "Unlinked",
  settingsDriver_driversFacesNoVh: "No Vehicle Assigned",
  settingsDriver_driversFacesSelectTempUrl: 'select this image as temporary avatar',
  settingsDriver_driversFacesDetele: 'Delete this face',
  settingsDriver_driversFacesAssignedTo: "Assigned To",
  settingsDriver_driversFacesSelectDriver: "Assign these faces to the driver:",
  settingsDriver_driversFacesCleanSeleted: "Deselect all",
  settingsDriver_driversFacesUpdateSuccess: "the selected images were correctly assigned to the selected driver",
  settingsDriver_driversFacesUpdateError: "An error occurred while assigning the images",
  settingsDriver_driversFacesdeleteCollection: "Are you sure you want to delete these driver collections?",
  settingsDriver_driversFacesDeleteCollectionSuccess: "the driver collection was successfully deleted",
  settingsDriver_driversFacesDeleteCollectionError: "An error occurred deleting the driver collection",
  settingsDriver_driversFacesDeleteCollectionTitle: "Delete Driver Collection",
  settingsDriver_driversFacesdeleteFaceId: "Are you sure you want to delete this face from this collection? This action can't be undone",
  settingsDriver_driversFacesdeleteFaceIdTitle: "Delete face from this collection",
  settingsDriver_driversFacesNoFaceDetected: "This collection has no images",



  settingsDriver_driversTagDelete: "Remove Drivers From Tags",
  settingsDriver_descriptonDriversTagDelete: "Are you sure you want to remove the drivers from the following tags?",
  settingsDriver_deletedDriversTag: "Drivers were removed from tags successfully",
  settingsDriver_searchTags: "Search by Tag",
  settingsDriver_avatar: "Profile Picture",
  settingsDriver_timeCard: "Time Card",
  settingsDriver_changeStatus: "Change Status",
  settingsDriver_successChangeStatus: "The status has been changed",
  settingsDriver_change: "Change",

  settingsDriver_externalId: "ID",
  settingsDriver_error_externalID_repeat: "Driver ID already exists",
  settingsDriver_error_externalID_long: "Driver ID to long, maximum 10 characters",
  settingsDriver_error_externalID: "Driver ID must contains only numbers and letters",
  settingsDriver_error_duplicated: "There is already a driver with the contact details, ID or name entered",
  settingsDriver_error_username_duplicated: "There is already a driver with the username entered",
  settingsDriver_error_invalid_name: "Driver name is invalid, please remove numbers and special characters",
};
