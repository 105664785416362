import vidFleetVideoRedux from 'Modules/.common/components/VidFleetVideo/redux/reducer';
import infoBubbleRedux from 'Modules/.common/components/InfoBubble/redux/reducer';
import dtcRedux from 'Modules/.common/components/Dtc/redux/slice';
import sharedMediaRedux from 'Modules/.common/components/SharedMedia/redux/reducer';
import entityPickerRedux from 'Modules/.common/components/EntityPicker/redux/reducer';
import accountInformationRedux from 'Modules/.common/components/AccountInformation/redux/reducer';
import smartwitnessVideoRedux from 'Modules/.common/components/SmartWitnessVideo/redux/reducer';
import iconPickerRedux from 'Modules/.common/components/IconPicker/redux/reducer';
import tagPickerRedux from 'Modules/.common/components/TagPicker/redux/reducer';
import entityTagPickerRedux from 'Modules/.common/components/EntityTagPicker/redux/reducer';
import newEntityTagPickerRedux from 'Modules/.common/components/NewEntityTagPicker/redux/reducer';
import SliderRangeVideoRedux from 'Modules/.common/components/SliderRangePanel/redux/reducer';
import troubleshootDeviceRedux from 'Modules/.common/components/TroubleshootDevice/redux/slice';
import warningsRedux from 'Modules/.common/components/Warnings/redux/slice';
import warningsDetailsRedux from 'Modules/.common/components/Warnings/.components/Details/redux/slice';
import warningsConfirmResolveRedux from 'Modules/.common/components/Warnings/.components/ConfirmResolve/redux/slice';
import warningsResolveRedux from 'Modules/.common/components/Warnings/.components/Resolve/redux/slice';
import warningsDeleteRedux from 'Modules/.common/components/Warnings/.components/Delete/redux/slice';

export default {
	vidFleetVideoRedux,
	infoBubbleRedux,
	dtcRedux,
	sharedMediaRedux,
	entityPickerRedux,
	accountInformationRedux,
	smartwitnessVideoRedux,
	iconPickerRedux,
	tagPickerRedux,
	entityTagPickerRedux,
	newEntityTagPickerRedux,
	SliderRangeVideoRedux,
	troubleshootDeviceRedux,
	warningsRedux,
	warningsDetailsRedux,
	warningsConfirmResolveRedux,
	warningsResolveRedux,
	warningsDeleteRedux
};