export default {
    fleet_ping_modal_title: 'Sending Ping',
    fleet_ping_vehicles:'Vehicle',
    fleet_ping_success:'Success',
    fleet_ping_unreachable:'Unreachable',
    fleet_ping_unknown:'Unknown',
    fleet_ping_loading:'Loading',
    fleet_ping_error_title:'Error',
    fleet_ping_error_message:'Could not make ping to selected units',
    fleet_ping_not_supported: 'Not Supported',
    fleet_ping_not_sms_command_config: 'There is not SMS Command Configuration',
};