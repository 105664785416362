//imports
import { all, call, takeEvery, fork, put, select } from "@redux-saga/core/effects";
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import moment from 'moment';
import _ from 'lodash'
import { LogManagerConsole } from 'Core/logManager';

//import { LogManager } from 'Core/logManager';

//action types
import {
	CATALOG_LOAD,
	CATALOG_INITIAL,
	CATALOG_INITIAL_REFRESH,
	CATALOG_SYNC_PUSH_NOTIFICATIONS,
	CATALOG_INITIAL_GET_UNIT
} from 'Redux/actionTypes';

//actions
import {
	catalogLoadResult,
	catalogLoadign,
	catalogInitialResult,
	catalogProcessItems,
	catalogInitialRefreshResult,
	catalogProcessItem,
	catalogInitialGetUnitResult,
	catalogAddItem,
	catalogRemoveItem
} from 'Redux/actions';
import { getApiDate } from "../../hooks/useUnitData";

const getCatalogs = state => state.catalogsRedux;

export function* loadCatalogs() {
	yield takeEvery(CATALOG_LOAD, function* ({ payload }) {


		try {
			const { keys, force } = payload; //Flag force to update elements.
			let querys = [];
			let localData = {};
			let loadings = {};
			const values = yield select(getCatalogs)

			keys.forEach((key) => {
				let tempdata = sessionStorage.getItem('catalog_' + key);
				if (tempdata) tempdata = JSON.parse(tempdata);

				let getCatalog = true;
				switch (key) {
					case 'drivers':
						loadings.loadingDrivers = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								drivers: getDrivers {
										name
										externalId
										driverId
										status
										companyId
										contactInfo
										groupName
								}
							`);
						}
						else
							localData = { ...localData, drivers: tempdata.items };
						break;
					case 'driversIgnoreStatus':
						loadings.loadingDrivers = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								driversIgnoreStatus: getDrivers(includeInactiveDrivers: true) {
										name
										externalId
										driverId
										status
										companyId
										contactInfo
										groupName
								}
							`);
						}
						else
							localData = { ...localData, driversIgnoreStatus: tempdata.items };
						break;

					/* case 'driverGroups':
						loadings.loadingDriverGroups = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								driverGroups: searchDriverGroups {
										id
										name
								}
							`);
						}
						else
							localData = { ...localData, driverGroups: tempdata.items };
						break; */

					/*case 'unitGroups':
						loadings.loadingUnitGroups = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								unitGroups: searchUnitGroups {
										id
										name
										color
								}
							`);
						}
						else
							localData = { ...localData, unitGroups: tempdata.items };
						break;*/

					/* case 'unitGroupsList':
						loadings.loadingUnitGroupsList = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								unitGroupsList: searchUnitGroups {
										id
										name
										color
								}
							`);
						}
						else
							localData = { ...localData, unitGroupsList: tempdata.items };
						break; */

					case 'contacts':
						loadings.loadingContacts = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								contacts: searchContacts {
									id
									title
									address
									type
								}
							`);
						}
						else
							localData = { ...localData, contacts: tempdata.items };
						break;

					case 'locations':
						loadings.loadingContacts = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								locations: searchLocations {
									id
									title
									active
								}
							`);
						}
						else
							localData = { ...localData, locations: tempdata.items };
						break;

					case 'timeZones':
						loadings.loadingContacts = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								timeZones: searchTimeZones {
									id
									name
								}
							`);
						}
						else
							localData = { ...localData, timeZones: tempdata.items };
						break;

					case 'inputLabels':
						loadings.loadingInputLabels = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								inputLabels: searchInputLabels {
									id
									title
									onLabel
									offLabel
							}
							`);
						}
						else
							localData = { ...localData, inputLabels: tempdata.items };
						break;

					case 'unitsLastLocation':
						loadings.loadingUnitsLastLocation = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								unitsLastLocation: searchUnits {
									id
									label
									driverName
									driverExternalId
									lastReading {
										latitude
										longitude
										driverName
										address {
											address
											city
											formatted
											state
											zip
										}
									}
								}
							`);
						}
						else
							localData = { ...localData, unitsLastLocation: tempdata.items };
						break;

					case 'unitsList':
						loadings.loadingUnitsList = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								unitsList: searchUnits {
									id
									label
									esn
									unitVersionId
									vbus
									jbus
									useEcuOdometer
									offsetEngineOdometer
									offsetEcuEngineOdometer
									manufacturerId
									manufacturerName
									tow
									driverBehavior
									unitVersionProduct
									timeZone {
										id
										name
									}
									linkedUnitId
									linkedMode
									linkedUnit {
										id
										label
										manufacturerName
										esn
										timeZone {
											id
											name
										}
									}
									sdCard
									maintenanceWarningEventsCount
									isOffline
								}
							`);
						}
						else
							localData = { ...localData, unitsList: tempdata.items };
						break;
					case 'unitListToShow':
						loadings.loadingUnitsList = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
									unitListToShow: searchUnitsStatus(ignoreStatus: true) {
										id
										label
										unitVersionId
										vbus
										jbus
										useEcuOdometer
										offsetEngineOdometer
										offsetEcuEngineOdometer
										manufacturerId
										tow
										driverBehavior
										unitVersionProduct
									}
								`);
						}
						else
							localData = { ...localData, unitListToShow: tempdata.items };
						break;
					case 'maintenanceServicesItems':
						loadings.loadingMaintenanceServicesItems = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
									maintenanceServicesItems: searchMaintenanceServiceItems {
										id
										name
									}
								`);
						}
						else
							localData = { ...localData, maintenanceServicesItems: tempdata.items };
						break;

					case 'vehicleStatus':
						loadings.loadingMaintenanceServicesItems = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								vehicleStatus: searchVehicleStatuses{
									id
									name
									iconId
									icon
									className
								}
							`);
						}
						else
							localData = { ...localData, maintenanceServicesItems: tempdata.items };
						break;

					case 'cellCarriers':
						loadings.loadingCellCarriers = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								cellCarriers: searchCellCarriers {
									id
									carrier
									alias
									gateway
								}
							`);
						} else {
							localData = { ...localData, cellCarriers: tempdata.items };
						}

						break;

					case 'subusers':
						loadings.loadingSubusers = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								subusers: searchSubUsers (openSearch: "", limit: 0, offset: 0) {
									items {
										id
										userName
										email
									}
									total
								}
							`);
						} else {
							localData = { ...localData, subusers: tempdata.items };
						}

						break;

					case 'forms':
						loadings.loadingForms = true;
						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(
								`
								forms: searchForms (openSearch: "", limit: 0, offset: 0) {
									items {
										id
										name
									}
									total
								}
							`);
						} else {
							localData = { ...localData, forms: tempdata.items };
						}

						break;

					case 'chatUsers':
						loadings.loadingChatUsers = true

						if (tempdata && tempdata.date && !force) {
							let date = moment(tempdata.date).add(60, 'seconds');
							if (date > moment()) {
								getCatalog = false;
							}
						}

						if (getCatalog) {
							querys.push(`
							chatUsers: searchContactChatUser (openSearch: "", limit: 0, offset: 0) {
									authId
									entityName
									entityId
									label
									lastConnected
									lastWriting
									avatarFileId
									avatarFile {
										id
										fileKey
										repositoryPath
									} 
								}
							`)
						} else {
							localData = { ...localData, chatUsers: tempdata.items };
						}

						break;
				}

			});

			yield put(catalogLoadign(loadings));


			let finalQuery = 'query { ';
			querys.forEach((x) => {
				finalQuery += x;
			});
			finalQuery += ' }';

			let data = {};
			if (querys.length > 0)
				data = yield call(clientQuery, finalQuery, {}, endpoints.GRAPHQL_GENERAL);

			keys.forEach((key) => {
				if (data[key])
					sessionStorage.setItem(
						'catalog_' + key,
						JSON.stringify({ date: moment(), items: data[key]?.items || data[key] }),
					);
			});

			for (let i in loadings) {
				loadings[i] = false;
			}

			let newData = {};
			for (const [key, value] of Object.entries(data)) {
				newData[key] = value?.items || value;
			}

			if (newData.driverGroups) {
				newData.driverGroups = data.driverGroups.map(x => {
					return { ...x, colorName: "#000000" }
				})
			}

			if (newData.chatUsers) {

				const chatUsers = values?.chatUsers
				newData.chatUsers = newData.chatUsers.map(x => {
					const oldItem = chatUsers.find(c => c.authId == x.authId)
					return { ...x, lastConnected: oldItem?.lastConnected, lastWriting: oldItem?.lastWriting }
				})
			}

			if (localData.chatUsers) {

				const chatUsers = values?.chatUsers
				localData.chatUsers = localData.chatUsers.map(x => {
					const oldItem = chatUsers.find(c => c.authId == x.authId)
					return { ...x, lastConnected: oldItem?.lastConnected, lastWriting: oldItem?.lastWriting }
				})
			}

			//console.log("catalogs demand", data)
			yield put(catalogLoadResult({ ...newData, ...localData, ...loadings }));
		} catch (exc) {
			// catch throw
			ExceptionManager(exc, 'Core/catalogs/redux/saga', 'loadCatalogsRequest');
			//yield put(loginResult(null, "Incorrect Username or Password"));
		}
	});
}

export function* loadInitialCatalogs() {
	yield takeEvery(CATALOG_INITIAL, function* () {
		try {

			const data = yield call(clientQuery,
				`
				query {
					units: searchUnits {
						id
						label
						esn
						imei
						driverId
						driverName
						driverExternalId
						useEcuOdometer
						offsetEngineOdometer
						offsetEcuEngineOdometer
						useEcuEngineHours
						offsetEngineHours
						offsetEcuEngineHours
						useEcuSpeed
						jbus
						vbus
						vehicleStatusId
						deviceCategory
						unitVersionId
						unitVersionProduct
						odometerEnable
                		hoursEnable
						starter
						desiredStarterState
						timeZone {
							id
							name
						}
						typeId
						type
						supportedOutputs
						supportedInputs
						linkedUnitId
  						linkedMode
						linkedUnit {
							id
							label
							manufacturerName
							esn
							timeZone {
								id
								name
							}
							
						}
						sdCard
						maintenanceWarningEventsCount
						isOffline
					}

					readings: searchLastReadings {
						deviceId
						deviceLabel		
						make
						model
						vin
						year
						unitTime
						eventCode
						eventName
						latitude
						longitude
						engineHours
						engineOdometer
						starterSupported
						starterDisabled
						dtc
						desiredStarterState
						heading
						serverTime
						LastMessageTime
						address {
							address
							city
							formatted
							state
							zip
						}
						ecuEngineHours
						ecuEngineOdometer 
						ecuVin 
						ecuSpeed
						speed
						speedLimit
						unitType
						landmark_id
						landmarkname
						deviceData
						temperatures
					}

					vehiclesInformation: searchVehicleInformation (openSearch: "", limit: 0, offset: 0) {
						items {
							unitId
							vin
							make
							model
							year
							engine
							mpgHighway
							mpgCity
							licensePlate
						}
					}

					tags: searchTags (openSearch: "", limit: 0, offset: 0, parentId: 0) {
						items {
							id
							label
							color
							parentId
							path
							hasChildren
							disabled
							unitsCount
							driversCount
						}
					}

					unitTags: searchTagsAndEntitiesNested (openSearch: "", limit: 0, offset: 0, entityName: Unit, onlyEntities: true) {
						items {
							unitId: id
							tagId: parentTagId
						}
					}
				}
				`, {}, endpoints.GRAPHQL_GENERAL);



			// console.log("loadInitialCatalogs", data);
			yield put(catalogInitialResult({
				units: data.units.map(x => { return { ...x, hasVideo: x.linkedUnitId > 0 ? true : false } }) || [],
				loadingUnits: false,
				readings: data.readings.map(x => { return { ...x, haveLatLng: true, serverTime: getApiDate(x.serverTime), unitTime: getApiDate(x.unitTime), LastMessageTime: getApiDate(x.LastMessageTime) } }) || [],
				loadingReadings: false,
				pmDevices: [],
				vehiclesInformation: data.vehiclesInformation?.items || [],
				unitGroups: [],
				unitTags: data.unitTags?.items || [],
				tags: data.tags?.items || [],
				loadingTags: false
			}))

			let unitsDiferential = data.readings.map(x => { return { deviceId: x.deviceId, unitTime: getApiDate(x.unitTime) } })
			sessionStorage.setItem("unitsDiferential", JSON.stringify(unitsDiferential))
		} catch (exc) {
			// catch throw
			ExceptionManager(exc, 'Core/catalogs/redux/saga', 'loadCatalogInitialRequest');
			//yield put(catalogInitialResult())
		}
	});
}

function* catalogInitialRefreshRequest() {
	yield takeEvery(CATALOG_INITIAL_REFRESH, function* ({ payload }) {
		const { refreshTime } = payload
		try {

			let devices = sessionStorage.getItem("unitsDiferential")
			devices = devices && JSON.parse(devices) || []
			devices = devices.filter(x => {
				let unitTime = moment(Number(x.unitTime))
				return unitTime.unix() > moment().add(-12, "hours").unix() && unitTime.unix() <= moment().add((-refreshTime), "seconds").unix()
			})
			const data = yield call(clientQuery,
				`
				query searchDifferentialReadings($devices: [DeviceLastReportInput]){
					readings: searchDifferentialReadings(devices: $devices){
						deviceId
						deviceLabel
						make
						model
						vin
						year
						unitTime
						eventCode
						eventName
						latitude
						longitude
						engineHours
						engineOdometer
						starterSupported
						starterDisabled
						dtc
						desiredStarterState
						heading
						serverTime
						address {
							address
							city
							formatted
							state
							zip
						}
						ecuEngineHours
						ecuEngineOdometer 
						ecuVin 
						speed
						ecuSpeed
						unitType
						landmark_id
						landmarkname
						deviceData
						trail{
							lat
							lon
							timestamp
						}
					}
				}
				`, { devices: devices }, endpoints.GRAPHQL_GENERAL);



			let readings = data.readings || []
			LogManagerConsole("DC_Diferencial", "catalogInitialRefreshRequest", readings.length);
			while (readings.length > 100) {
				let sendReadings = readings.slice(0, 99)
				readings = readings.slice(99, readings.length)
				LogManagerConsole("DC_Diferencial", "catalogInitialRefreshRequest", "Send", sendReadings.length);
				LogManagerConsole("DC_Diferencial_Detail", "catalogInitialRefreshRequest", "Send", sendReadings);
				yield put(catalogProcessItems("LastReading", sendReadings))
			}

			LogManagerConsole("DC_Diferencial", "catalogInitialRefreshRequest", "Send", readings.length);
			LogManagerConsole("DC_Diferencial_Detail", "catalogInitialRefreshRequest", "Send", readings);
			yield put(catalogProcessItems("LastReading", readings))
			yield put(catalogInitialRefreshResult())
		} catch (exc) {
			ExceptionManager(exc, 'Core/catalogs/redux/saga', 'catalogInitialRefreshRequest');
		}
	});
}

function* catalogSyncPushNotificationsRequest() {
	yield takeEvery(CATALOG_SYNC_PUSH_NOTIFICATIONS, function* ({ payload }) {
		const { sessionId, accountId, dateRange } = payload
		try {

			const data = yield call(clientQuery,
				`
				query searchPushNotifications ($sessionId: String, $accountId: String, $dateRange: AWSDateTimeRange, $limit: Int, $nextToken: String) {
					pushNotifications: searchPushNotifications (sessionId: $sessionId, accountId: $accountId, dateRange: $dateRange, limit: $limit, nextToken: $nextToken) {
						items {
							id
							sessionId
							accountId
							payload
							createdOn
						}
						nextToken
					}
				}
				`, {
				sessionId, accountId, dateRange, limit: 100
			}, endpoints.GRAPHQL_GENERAL);

			LogManagerConsole("DC_Diferencial", "catalogSyncPushNotificationsRequest", data);
			let pushNotifications = []

			if (data.pushNotifications)
				for (let itemString of data.pushNotifications.items) {
					let item
					try {
						//TODO: Temporal to support Reports
						item = JSON.parse(JSON.parse(itemString.payload)).message
						yield put(catalogProcessItem("Notification", item))
					} catch (e) {
						item = JSON.parse(itemString.payload)
						yield put(catalogProcessItem("Notification", item))
					}
					pushNotifications.push(item)
				}

		} catch (exc) {
			ExceptionManager(exc, 'Core/catalogs/redux/saga', 'catalogSyncPushNotificationsRequest');
		}
	});
}

export function* catalogInitialGetUnitRequest() {
	yield takeEvery(CATALOG_INITIAL_GET_UNIT, function* ({ payload }) {
		const { ids } = payload;
		try {
			let data = yield call(
				clientQuery,
				`
				query {
					units: searchUnits(ids: [${ids.join(", ")}]){
						id
						label
						esn
						imei
						driverId
						driverName
						driverExternalId
						useEcuOdometer
						offsetEngineOdometer
						offsetEcuEngineOdometer
						useEcuEngineHours
						offsetEngineHours
						offsetEcuEngineHours
						useEcuSpeed
						jbus
						vbus
						vehicleStatusId
						deviceCategory
						unitVersionId
						unitVersionProduct
						odometerEnable
                		hoursEnable
						starter
						desiredStarterState
						timeZone {
							id
							name
						}
						typeId
						type
						supportedOutputs
						supportedInputs
						linkedUnitId
  						linkedMode
						linkedUnit {
							id
							label
							manufacturerName
							esn
							timeZone {
								id
								name
							}
							
						}
						tags {
							id
							label
							color
							parentId
							path
							hasChildren
						}
						sdCard
						maintenanceWarningEventsCount
						isOffline
					}
				}`,
				{
					ids: ids
				},
				endpoints.GRAPHQL_GENERAL,
			);

			
			if(data){
				yield put(catalogInitialGetUnitResult(false));
				for (const unitId of ids) {
					let findIndex = data.units.findIndex((item)  => item.id == unitId)
					if(findIndex > -1){
						//add unit
						yield put(catalogAddItem({ entity: 'units', values: data.units[findIndex], procesor: "Unit" }));
					}else{
						//remove units from the view
						yield put(catalogRemoveItem({ entity: 'units', values: {id: unitId}, procesor: "Unit" }));
					}
				}
					
			}
				
			 
		} catch (exc) {
			yield put(catalogInitialGetUnitResult(false))
			ExceptionManager(exc, 'Core/catalogs/redux/saga', 'catalogInitialGetUnitRequest');
			
		}
	});
}


//MERGE SAGA
export default function* rootSaga() {
	yield all([fork(loadCatalogs)]);
	yield all([fork(loadInitialCatalogs)]);
	yield all([fork(catalogInitialRefreshRequest)]);
	yield all([fork(catalogSyncPushNotificationsRequest)]);
	yield all([fork(catalogInitialGetUnitRequest)]);

}
