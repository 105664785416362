export default {
    fleet_ping_modal_title: 'Enviar Ping',
    fleet_ping_vehicles:'Vehículo',
    fleet_ping_success:'Envio Exitoso',
    fleet_ping_unreachable:'Fuera De Alcance',
    fleet_ping_unknown:'Desconocido',
    fleet_ping_error_title:'Error',
    fleet_ping_error_message:'No puede Hacer Ping A Las Unidades Seleccionadas',
    fleet_ping_loading:'Cargando',
    fleet_ping_not_supported: 'No es compatible',
    fleet_ping_not_sms_command_config: 'No hay configuración de comandos SMS',
};