import React, { useEffect,useState,forwardRef,useRef,useImperativeHandle } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { endpoints } from 'Core/defaultValues';
// Components
import _ from 'lodash';
import Carousel from "react-material-ui-carousel";
import { download } from 'Core/data/Helpers';
import { useIntl } from 'react-intl';
import { IconButton,Toolbar  } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageIcon from '@mui/icons-material/Image';
import TheatersIcon from '@mui/icons-material/Theaters';
import StopIcon from '@mui/icons-material/Pause';
// Styles
import './smartwitness.scss';
import { Grid,Typography} from '@mui/material';

const SmartWitnessSnapshot = forwardRef((props, ref) => {
  
  const { vehicleTrail,response,height,indicators=true } = props;
  const { messages } = useIntl();
  const [value, setValue] = useState(0);
  const [urls, setUrls] = useState([]);
  const [prevUrl, setPrevUrl] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const mediaRefs = useRef([]);

  useEffect(()=>{
    changeImageUrl()
  },[value])

  useEffect(() => {
    let interval;
    if (isActive && prevUrl) {
        interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % Object.values(prevUrl)[0]?.length);
        }, 1000);  
    }

    return () => clearInterval(interval); 
}, [isActive]);

useEffect(() => {
  if (isActive && Object.values(prevUrl)?.length && Object.values(prevUrl)[0][activeIndex]?.url?.includes('.mp4')){
    setIsActive(false)
  }
}, [activeIndex,isActive]);

  const changeImageUrl = ()=> {
    if(vehicleTrail?.deviceData || response?.details){
      let deviceData = vehicleTrail?.deviceData?JSON.parse(vehicleTrail?.deviceData):JSON.parse(response?.details)
      deviceData = JSON.parse(deviceData)
      if(deviceData?.urls){
        let groupedprevUrl = deviceData?.urls.reduce((acc, image) => {
          if (!acc[image.camera]) {
              acc[image.camera] = [];
          }
            acc[image.camera].push(image);
            return acc;
        }, {});
        let groupedByCamera =  deviceData?.urls.filter(i => i?.camera == Object.keys(groupedprevUrl)[value]);
        setUrls(groupedByCamera);
        setPrevUrl(groupedprevUrl);
      }
    }
  }

  useImperativeHandle(ref, () => ({
    downloadVideo: downloadPicture,
    downloadPicture: downloadPicture
  })); 

  const downloadPicture = async () => {
    if(urls.length){
      for await (const url of urls) {
        download(`${endpoints.SMARTWITNESS_API_URL}${url?.url}`,url?.url.split('/').pop())
      }
    }
  };
 
  const handleChange = (event, newValue) => {
    setUrls([]);
    setValue(newValue);
  };


  return (
    <div style={{ display: 'flex',height:"100%",width:"100%",background:"white" }}>
      {indicators && (
        <div style={{ height: "100%", width: "100%" }}>
          <Tabs
            value={activeIndex}
            onChange={(event, newValue)=> setActiveIndex(newValue)} 
              className='tabSmaritnessPictures'
            >
            {Object.values(prevUrl)?.length && Object.values(prevUrl)[0].map((tab, index) => (
              <Tab key={index} icon={tab?.url.includes('.mp4')?<TheatersIcon />:<ImageIcon />} iconPosition="start" label={index+1}  />
            ))}
          </Tabs>
          <Grid container spacing={1} style={{ height: "100%" }}>
            {Object.values(prevUrl)?.length &&
                Object.values(prevUrl)?.map((tab, index) => (
                <Grid item sm={6} >
                  <div className='titleVideoIndicator'>
                    <VideocamIcon style={{position:"absolute"}} />
                    <Typography color="inherit" style={{marginLeft:25}} >{messages["videoDetail_camera"]} {index + 1}</Typography>
                  </div>
                  <Carousel
                    fullHeightHover={false}
                    autoPlay={false}
                    interval={1500}
                    indicators={false}
                    index={activeIndex}
                    onChange={(newIndex) => setActiveIndex(newIndex)} 
                    animation="slide"
                    style={{ height: "100%", width: "100%" }}
                  >
                    {tab.map((item, i) => (
                      <Content
                        key={i}
                        id={item}
                        height="30vh"
                        url={`${endpoints.SMARTWITNESS_API_URL}/${item.url}`}
                        mediaRefs={mediaRefs}
                      />
                    ))}
                  </Carousel>
                </Grid>
            ))}
          </Grid>
          <div position="static" style={{color:"black",width: '100%',alignItems:"center",background:"#f7f9fc",display: "flex",flexDirection: "column"}}>
            <Toolbar style={{ width: 'auto'}}>
              <IconButton color="inherit"disabled={isActive} >
                <SkipPreviousIcon style={{fontSize:40}} 
                  onClick={() => {
                    let newIndex = activeIndex - 1
                    if(newIndex >= 0)
                      setActiveIndex(newIndex)
                  }}/>
              </IconButton>
              <IconButton color="inherit" >
                {
                  !isActive && 
                    <PlayIcon style={{fontSize:40}} onClick={() => setIsActive(true)}/>
                }

                {
                  isActive && 
                    <StopIcon style={{fontSize:40}} onClick={() => setIsActive(false)} />
                }
                
              </IconButton>
              <IconButton color="inherit" disabled={isActive}>
                <SkipNextIcon style={{fontSize:40}} 
                     onClick={() => {
                          let newIndex = activeIndex + 1
                          if(newIndex < Object.values(prevUrl)[0]?.length)
                          setActiveIndex(newIndex)
                        }} />
              </IconButton>
            </Toolbar>
          </div>
        </div>

      )}

      {
        !indicators && 
            <div style={{ width:"100%",height:"100%"}}>
              {urls?.length &&  
                <Carousel
                  fullHeightHover={true}
                  autoPlay={false}
                  interval={3000}
                  height={height}
                  indicators={indicators}
                  animation="slide">
                    {urls?.map((item, i) => (
                      <Content
                            key={i}
                            id={item}
                            height="100%"
                            url={`${endpoints.SMARTWITNESS_API_URL}/${item.url}`}
                            mediaRefs={mediaRefs}
                        />
                    ))}
                </Carousel>
              }
            </div>

      } 

      {
        !indicators && 
          <Tabs
            value={value}
            onChange={handleChange}
            className='tabSmaritnessCameraHorizontal'
          >
            {Object.keys(prevUrl)?.length && Object.keys(prevUrl)?.map((tab, index) => (
              <Tab key={tab} icon={<VideocamIcon />} iconPosition="start" label={index+1}  />
            ))}
          </Tabs>
      } 


    </div>
  );
});

export default SmartWitnessSnapshot;


export const Content = ({
  url,
  height,
  mediaRefs,
  id
}) => {

  const handlePlay = () => {
    mediaRefs.current.forEach((videoRef, i) => {
        videoRef.play();
    });
  };

  const handlePause = () => {
    mediaRefs.current.forEach((videoRef, i) => {
        videoRef.pause();
    });
  };

  return (
      <div
          style={{
            width:"100%",
            height:height,
            background:"silver"
          }}
          >
          {url.includes('.mp4') ? (
            <video controls  style={{ width: "100%",height:"100%",position: "relative",objectFit: "cover" }}  
               ref={(el) => (mediaRefs.current[id.camera] = el)}
               className="videoSWContent"
              onPlay={() => handlePlay()}
              onPause={() => handlePause()}>
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <img
              style={{ width: "100%",height:"100%",position: "relative" }}
              src={url}
            />
          )}

      </div>
  );
};