export default {
  videoMainView_availableTab: 'Available',
  videoMainView_deletedTab: 'Deleted',
  videoMainView_starredTab: 'Starred',
  videoMainView_completedTab: 'Completed',
  videoMainView_pendingTab: 'Pending',
  videoMainView_failedTab: 'Failed',
  videoMainView_inProgressTab: 'In Progress',
  videoMainView_clipRequestTab: 'Clip Request',
  videoMainView_eventType: 'Event Type',
  videoMainView_availableMedia: 'Available Media',
  videoMainView_events: 'Events',
  videoMainView_requested: 'Requested',
  videoMainView_starred: 'Starred',
  videoMainView_deleted: 'Deleted',
  videoMainView_all: 'All',
  videoMainView_deleteEventSuccess: 'The event has been successfully removed',
  videoMainView_updateEventSuccess: 'The event has been successfully updated',
  videoMainView_deleteEventsSuccess: 'The events has been successfully removed',
  videoMainView_selectOption: ' Please select at least one option',
  videoMainView_restoreEventSuccess: 'The event has been successfully restored',
  videoMainView_restoreEventsSuccess: 'The events has been successfully restored',
  videoMainView_manageCameras: 'Manage Cameras',
  // Event Codes
  // https://gpstrackit.atlassian.net/wiki/spaces/WIKI/pages/645431301/Event-Codes
  videoMainView_drivingHit: 'Sudden Stop',
  videoMainView_drivingheavyHit: 'Sudden Stop',
  //videoMainView_hardAccel: 'Hard Acceleration',
  videoMainView_hardAccel: 'Rapid Acceleration',
  videoMainView_hardBrake: 'Hard Brake',
  videoMainView_sharpTurn: 'Hard Turn',
  //videoMainView_harshAccel: 'Hard Acceleration',
  videoMainView_harshAccel: 'Rapid Acceleration',
  videoMainView_harshBrake: 'Hard Brake',
  videoMainView_harshTurn: 'Hard Turn',
  //videoMainView_severeAccel: 'Hard Acceleration',
  videoMainView_severeAccel: 'Rapid Acceleration',
  videoMainView_severeBrake: 'Hard Brake',
  videoMainView_severeTurn: 'Hard Turn',
  videoMainView_asleep: 'Asleep',
  videoMainView_drowsy: 'Drowsy',
  videoMainView_yawn: 'Yawn',
  videoMainView_noDriver: 'Camera Obstructed',
  videoMainView_dayDreaming: 'Day Dreaming',
  videoMainView_autoSnapshot: 'AutoSnapshot',
  videoMainView_addNote: 'Add Note',
  videoMainView_share: 'Share',
  videoMainView_delete: 'Delete',
  videoMainView_noteAdded: 'Note saved successfully',
  videoMainView_note: "Note",
  videoMainView_restore: "Restore",
  videoMainView_manual: 'Manual',
  videoMainView_usingPhone: "Using Phone",
  videoMainView_distracted: "Distracted",
  videoMainView_smoking: "Smoking",
  videoMainView_noSeatBelt: "No Seatbelt",
  videoMainView_addStarred: "Add to starred",
  videoMainView_removeStarred: "Remove from starred",
  videoMainView_collisionWarning: "Collision Warning",
  videoMainView_closeFollowing: "Close Following",
  videoMainView_tailgating: "Tailgating",
  videoMainView_stopSignViolation: "Stop Sign Violation",
  videoMainView_TitleTimeLine: "Select Clip from Timeline",
  videoMainView_subtitleTimeLine: 'Drag the black selector to the section of the timeline that you’re interested in and click “Get Clip”.',
  videoMainView_selectorWarning: "The selector must be in an area with data",
  videoMainView_snapshots: "Snapshots",
  videoMainView_videoClips: "Video Clips",
  videoMainView_mediaTypeBoth: "Both",
  videoMainView_noDriver: "Camera Obstructed",
  videoMainView_idleNoDriver: "Idle No Driver",
  videoMainView_requestClip: "Request clip",
  videoMainView_laneDeparture: "Lane Departure",
  videoMainView_potentialIncident: "Potential Incident",
  videoMainView_errorRequestClip: "The selected video clip is duplicated, select another time range",
  videoMainView_headwayMonitoringWarning: "Close Following",
  videoMainView_forwardCollisionWarning: "Collision Warning",
  videoMainView_headwayMonitoringEmergency: "Tailgating",
  videoMainView_NO_SEATBELT: "No Seatbelt",
  videoMainView_noValidTime: "Hour no valid",
  videoMainView_reviewStatus: "Review Status",
  videoMainView_unreviewed: "Unreviewed",
  videoMainView_accepted: "Accepted",
  videoMainView_disputed: "Disputed",
  videoMainView_reviewAll: "All",
  videoMainView_newDriverFaceDetected:"New Driver Face Detected",

  videoMainView_PostedSpeed:"Posted Speed",
  videoMainView_speedThreshold :"Speed Threshold ",
  videoMainView_pedestrianDetection:"Pedestrian Detection",
  videoMainView_cyclistWarning:"Cyclist Warning",
  videoMainView_passengerInside:"Passenger Inside",
  videoMainView_passengerSeatbelt:"Passenger Seatbelt",

  videoMainView_AlarmOut:"Alarm Out",
  videoMainView_AlarmIn:"Alarm In",
  videoMainView_PanicButton:"Panic Button",
  videoMainView_cameraObstructed:"Camera Obstruction",
  videoMainView_Turn:"Turn",
  videoMainView_SevereShock:"Severe Shock",
  videoMainView_Shock:"Shock",
  videoMainView_Brake:"Brake",
  videoMainView_Accelerate:"Accelerate",
  videoMainView_IgnitionOff:"IgnitionOff",
  videoMainView_LocationChanged:"Location Changed",
  videoMainView_Moving:"Moving",
  videoMainView_IgnitionOn:"IgnitionOn",
  videoMainView_drowsiness: 'Drowsy',
  videoMainView_hardTurn: 'Hard Turn',
  videoMainView_rapidAcceleration: 'Rapid Acceleration',
  videoMainView_mainPowerDisconnect: "Desconectado principal",
  videoMainView_noSeatbelt: "No Seatbelt",
  videoMainView_clipPreview: "Clip Preview",
  videoMainView_fullSegment: "Full Segment",
  videoMainView_selectedTime: "Selected Time Only",
  videoMainView_send_streaming_clip: 'Search Preview Clip',
  videoMainView_deletedBy: "Deleted By",
  videoMainView_reviewed: "Reviewed",
  videoMainView_falsePositive: "False Positive",
  videoMainView_notes: "Notes",
};