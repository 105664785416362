//View Keys
export default {
  role_role: "Roles",
  role_permissions: "Permisos",
  role_alerts: "Alertas",
  role_reports: "Reportes",
  role_edit: "Editar Rol",
  role_create: "Crear un Nuevo Rol",
  role_label: "Etiqueta de Rol",
  role_minMessageUserType: "El rol debe contener un mínimo de caracteres:",
  role_maxMessageUserType: "El rol debe contener un máximo de caracteres:",
  role_globalAccess: "Acceso global",
  role_localAccess: "Acceso local",
  role_createMessage: "Rol Creado",
  role_editMessage: "Rol Actualizado",
  role_deleteMessage: "Rol Eliminado",
  role_messageDelete: "¿Estás seguro de que quieres eliminar los roles?",
  role_delete: "Eliminar Rol",
  role_custom: "Rol Personalizado"
};