import {
  //TYPES
  MAP_UTILITIES_SELECT_MODULE,
  MAP_UTILITIES_SHOW_MAP_SETTINGS,
} from 'Redux/actionTypes';

export const selectModuleMapSettings = (currentModule) => {
  return {
    type: MAP_UTILITIES_SELECT_MODULE,
    payload: { currentModule }
  };
}

export const setShowMapSettings = (showMapSettings) => {
  return {
    type: MAP_UTILITIES_SHOW_MAP_SETTINGS,
    payload: { showMapSettings }
  };
}