export default {
    "videoMainView_unit": "Unit",
    "videoMainView_driver": "Driver",
    "videoMainView_event": "Event",
    "videoMainView_address": "Address",
    "videoMainView_dateTime": "Date/Time",
    "videoMainView_speed": "Speed",
    "videoMainView_notes": "Notes",
    "videoMainView_event_id": "Event Id",
    "videoMainView_when": "When",
    "videoMainView_videoEvents": "Video: Events",
    "videoMainView_filters": "Filters:",
    "videoMainView_units": "Tags/Units/Drivers:",
    "videoMainView_events": "Events",
    "videoMainView_openSearch": "Search: ",
    "videoMainView_dateRange": "Date Range:",
    "videoMainView_hasNotes": "Has Notes",
    "videoMainView_dates_today": 'Today',
    "videoMainView_dates_yesterday": 'Yesterday',
    "videoMainView_dates_week": 'This Week',
    "videoMainView_dates_lastWeek": 'Last Week',
    "videoMainView_dates_quarter": 'This Quarter',
    "videoMainView_dates_lastQuarter": 'Last Quarter',
    "videoMainView_dates_custom": 'Custom',
    "videoMainView_dates_thisMonth": 'This Month',
    "videoMainView_dates_lastMonth": 'Last Month',
    "videoMainView_dates_last24hours": 'Last 24 Hours',
    "videoMainView_dates_last7days": 'Last 7 Days',
    "videoMainView_dates_last15days": 'Last 15 Days',
    "videoMainView_dates_last30Days": 'Last 30 Days',
    "videoMainView_dates_last90Days": 'Last 90 Days',
    "videoMainView_dates_last3month": 'Last 3 Months',
    "videoMainView_addNote": 'Add note...',
    "videoMainView_alertMessage": 'Videos will Expire after 90 days.',
    "videoMainView_download360Error": "360 videos cannot be downloaded at this time.",
    "videoMainView_eventId": "Event Id",
    "videoMainView_tags": "Tags",
    "videoMainView_changeStatus": "Change Status",
    "videoMainView_eventsSelected": "events selected",
};