import vidFleetComponent from 'Modules/.common/components/VidFleetVideo/lan/es';
import infoBubbleComponent from 'Modules/.common/components/InfoBubble/lan/es';
import dtcComponent from 'Modules/.common/components/Dtc/lan/es';
import sharedMediaComponent from 'Modules/.common/components/SharedMedia/lan/es';
import entityPickerComponent from 'Modules/.common/components/EntityPicker/lan/es';
import accountInformationComponent from 'Modules/.common/components/AccountInformation/lan/es';
import smartWitnessComponent from 'Modules/.common/components/SmartWitnessVideo/lan/es';
import mapIconPickerComponent from 'Modules/.common/components/IconPicker/lan/es';
import tagPickerComponent from 'Modules/.common/components/TagPicker/lan/es';
import entityTagPickerComponent from 'Modules/.common/components/EntityTagPicker/lan/es';
import entityTagPicker from 'Modules/.common/components/NewEntityTagPicker/lan/es';
import entityTagViewerComponent from 'Modules/.common/components/EntityTagViewer/lan/es';
import newTagPicker from 'Modules/.common/components/NewTagPicker/lan/es';
import troubleshootDevice from 'Modules/.common/components/TroubleshootDevice/lan/es';
import warnings from 'Modules/.common/components/Warnings/lan/es';

let locales = {}

locales = {
  exportMessageSuccess: 'La exportación ha sido generada correctamente.',
  exportMessageFailed: 'Lo sentimos, ocurrió un error durante la generación del archivo.',
  exportMessageFailedNoData: 'no se encontró información para exportar.',
  ...locales,
  ...vidFleetComponent,
  ...infoBubbleComponent,
  ...dtcComponent,
  ...sharedMediaComponent,
  ...entityPickerComponent,
  ...accountInformationComponent,
  ...smartWitnessComponent,
  ...mapIconPickerComponent,
  ...tagPickerComponent,
  ...entityTagPickerComponent,
  ...entityTagPicker,
  ...entityTagViewerComponent,
  ...newTagPicker,
  ...troubleshootDevice,
  ...warnings
};

export default locales;
