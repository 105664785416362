export default {
  settingsDriver_search: "Buscar por conductor o ID",
  settingsDriver_driver: "Conductor",
  settingsDriver_name: "Nombre",
  settingsDriver_vehicle: "Vehiculo",
  settingsDriver_timezone: "Zona horaria",
  settingsDriver_contact: "Contacto",
  settingsDriver_contactDetails: "Detalles de contacto",
  settingsDriver_cellphone: "Celular",
  settingsDriver_carrier: "Portador",
  settingsDriver_address: "Correo",
  settingsDriver_username: "Nombre de Usuario",
  settingsDriver_password: "Contraseña",
  settingsDriver_defaultTab: "Tab por defecto",
  settingsDriver_create: "Crear nuevo Conductor",
  settingsDriver_edit: "Editar Conductor",
  settingsDriver_delete: "Eliminar Conductor",
  settingsDriver_created: "Conductor Creado",
  settingsDriver_updated: "Conductor Actualizado",
  settingsDriver_deleted: "Conductor Eliminado",
  settingsDriver_fsmLogin: "Usuario de la Aplicación",
  settingsDriver_addFsmLogin: "Editar para crear",
  settingsDriver_minLengthMessageName: "El nombre de usuario debe contener un mínimo de caracteres:",
  settingsDriver_maxLengthMessageName: "El nombre de usuario debe contener un máximo de caracteres:",
  settingsDriver_minLengthMessagePassword: "La contraseña debe contener un mínimo de caracteres:",
  settingsDriver_maxLengthMessagePassword: "La contraseña debe contener un máximo de caracteres:",
  settingsDriver_maxLengthMessageCellPhone: "El teléfono celular debe contener un máximo de caracteres:",
  settingsDriver_minLengthMessageCellPhone: "El teléfono celular debe contener un mínimo de caracteres:",
  settingsDriver_addressMessage: "El correo es incorrecto.",
  settingsDriver_descriptonDelete: "¿Está seguro de que desea eliminar los Conductores?",
  settingsDriver_fmsLoginMessage: "Para crear o editar el inicio de sesión de FSM es necesario agregar los 2 campos siguientes:",
  settingsDriver_fmsLoginPwdRequired: 'La contraseña es requerida',
  settingsDriver_import:"Importar conductores",
  settingsDriver_descriptonImport:"Importar conductores por archivo csv",
  settingsDriver_import_driver:"Importar Conductores",
  settingsDriver_delimiter:"Delimitador",
  settingsDriver_file:"Archivo",
  settingsDriver_upload:"Cargar",
  settingsDriver_error_delimiter:"Error en el archivo, por favor confirma el delimitador",
  settingsDriver_error_name_required:"El nombre del conductor, el grupo y el Email son requeridos",
  settingsDriver_error_name_repeat:"El nombre del conductor o el Email estan repetidos",
  settingsDriver_error_name_repeat_file:"El nombre del conductor ya existe",
  settingsDriver_error_fix:"Por favor corregir los errores antes de continuar",
  settingsDriver_error_started:"La importacion se inicio correctamente,en un momento te indicaremos cuando termine",
  settingsDriver_error_required:"El archivo csv es requerido",
  settingsDriver_template:"Formato csv",
  settingsDriver_aceept_file:"Solo se aceptan archivos csv",
  settingsDriver_email: 'Email',
  settingsDriver_group: 'Etiqueta',
  settingsDriver_error: 'Error',
  settingsDriver_semicolon: 'Punto y coma',
  settingsDriver_comma: 'Coma',
  settingsDriver_resume:'Resumen de la transaccion',
  settingsDriver_importation_title:'Importacion terminada',
  settingsDriver_importation_body:'Para visualizar el resumen presione click en la notificacion',
  settingsDriver_status:'Estado',
  settingsDriver_tag:'Etiqueta',

  settingsDriver_filterBy:'Filtrar por',
  settingsDriver_clearFilters:'Limpiar filtros',

  settingsDriver_all: "Todo",
  settingsDriver_tags: "Etiquetas",
  settingsDriver_units: "Unidades",
  settingsDriver_unassigned: "Sin etiquetar",
  settingsDriver_drivers: "Conductores",
  settingsDriver_driversFaces: "Caras de conductores",
  settingsDriver_driversFacesAssign: "Asignar Conductor",
  settingsDriver_driversFacesAll: "Todos",
  settingsDriver_driversFacesLinked: "Vinculadas",
  settingsDriver_driversFacesUnlinked: "No vinculadas",
  settingsDriver_driversFacesAssignedTo: "Asignado A",
  settingsDriver_driversFacesNoVh: "Vehiculo no Asignado",
  settingsDriver_driversFacesDetele: 'Eliminar este rostro',
  settingsDriver_driversFacesSelectTempUrl: 'seleccionar esta imagen como avatar temporal',
  settingsDriver_driversFacesSelectDriver: "Asignar estas caras al conductor:",
  settingsDriver_driversFacesCleanSeleted: "Limpiar los elementos seleccionados",
  settingsDriver_driversFacesUpdateSuccess: "las imágenes seleccionadas fueron asignadas correctamente al conductor seleccionado",
  settingsDriver_driversFacesUpdateError: "Ocurrio un error asignando la imagenes",
  settingsDriver_driversFacesdeleteCollection: "¿Esta seguro que desea eliminar estas colecciones de conductores?",
  settingsDriver_driversFacesDeleteCollectionSuccess: "la coleccion de conductores fue eliminada correctamente",
  settingsDriver_driversFacesDeleteCollectionError: "ocurrio un error eliminando la coleccion de drivers",
  settingsDriver_driversFacesDeleteCollectionTitle: "Eliminar coleccion de conductor",
  settingsDriver_driversFacesdeleteFaceId: "¿Estás seguro de que quieres eliminar esta cara de esta coleccion? Esta acción no se puede deshacer.",
  settingsDriver_driversFacesdeleteFaceIdTitle: "Eliminar esta cara de esta coleccion",
  settingsDriver_driversFacesNoFaceDetected: "Esta coleccion no tiene imagenes",


  settingsDriver_driversTagDelete: "Remover Conductores de las Etiquetas",
  settingsDriver_descriptonDriversTagDelete: "¿Está seguro de que desea remover los conductores de las siguientes etiquetas?",
  settingsDriver_deletedDriversTag: "Los conductores se eliminaron de las etiquetas con éxito",
  settingsDriver_searchTags: "Buscar por etiqueta",
  settingsDriver_avatar: "Foto Perfil",
  settingsDriver_timeCard: "Tarjeta de tiempo",
  settingsDriver_changeStatus: "Cambiar Estado",
  settingsDriver_successChangeStatus: "El estado ha sido cambiado.",
  settingsDriver_change: "Cambiar",

  settingsDriver_externalId: "ID",
  settingsDriver_error_externalID_repeat:"El ID del conductor están repetidos",
  settingsDriver_error_externalID_long: "El ID del conductor debe tener máximo 10 caracteres",
  settingsDriver_error_externalID: "El ID del conductor debe contener solo números y letras",
  settingsDriver_error_duplicated: "Ya existe un conductor con los datos de contacto, ID o nombre ingresados",
  settingsDriver_error_username_duplicated: "Ya existe un conductor con el nombre de usuario ingresado",
  settingsDriver_error_invalid_name: "El nombre del conductor no es válido, elimine los números y caracteres especiales",
};
