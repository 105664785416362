// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import { 
  ROLE_SEARCH, 
  ROLE_GET,
  ROLE_CREATE,
  ROLE_UPDATE,
  ROLE_DELETE
} from "Redux/actionTypes";

// Actions
import { 
  searchRolesResponse, 
  getRoleResponse,
  createRoleResponse,
  updateRoleResponse,
  deleteRoleResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchRoles({ payload }) {
  const { openSearch, limit, offset } = payload;
  const query = `
    query searchRoles ($openSearch: String, $limit: Int, $offset: Int) {
      searchRoles (openSearch: $openSearch, limit: $limit, offset: $offset) {
          items {
              id
              name
              description
              modules {
                  id
                  code
                  userCode
                  name
                  groupName
                  parentModuleId
                  category
                  subModules {
                      id
                      code
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                      subModules {
                          id
                          code
                          userCode
                          name
                          groupName
                          parentModuleId
                          category
                      }
                  }
              }
          }
          total
      }
    }
    `;

  const variables = {
    openSearch,
    limit,
    offset
  };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { searchRoles: { items, total } } = data;
      yield put(searchRolesResponse({ items, total, error: null }));
    }
  } catch (exc) {
    yield put(searchRolesResponse({ 
      error: exc.error,
      items: [],
      total: 0
    }));
    ExceptionManager(
      exc,
      "modules/account/views/role/redux/sagas",
      "searchRole"
    );
  }
}

function* getRole({ payload }) {
  const { id } = payload;
  const query = `
    query getRole ($id: Int!) {
      getRole (id: $id) {
          id
          name
          description
          modules {
              id
              code
              userCode
              name
              groupName
              parentModuleId
              category
              subModules {
                  id
                  code
                  userCode
                  name
                  groupName
                  parentModuleId
                  category
                  subModules {
                      id
                      code
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                  }
              }
          }
      }
  }`;

  const variables = { id };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { getRole } = data;
      yield put(getRoleResponse({ role: getRole, error: null }));
    }
  } catch (exc) {
    yield put(getRoleResponse({ error: exc.error }));
    ExceptionManager(
      exc,
      "modules/account/views/role/redux/sagas",
      "getRole"
    );
  }
}

function* createRole({ payload }) {
  const {
    name,
    modules
  } = payload;

  const mutation = `
    mutation createRole ($name: String!, $description: String, $modules: [Int]) {
      createRole (name: $name, description: $description, modules: $modules) {
          id
          name
          description
          modules {
              id
              code
              userCode
              name
              groupName
              parentModuleId
              category
              subModules {
                  id
                  code
                  userCode
                  name
                  groupName
                  parentModuleId
                  category
                  subModules {
                      id
                      code
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                  }
              }
          }
      }
    }`;
  
  const variables = {
    name,
    modules
  };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(createRoleResponse({ 
        newRole: data.createRole ? true : false, 
        error: null 
      }));
    } else {
      throw 'Role has not been created';
    }
  } catch (exc) {
    yield put(
      createRoleResponse({
        error: exc.error,
        newRole: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/role/redux/sagas",
      "createRole",
      true,
    );
  }
}

function* updateRole({ payload }) {
  const { 
    id, 
    name,
    modules
  } = payload;

  const mutation = `
    mutation updateRole ($id: Int!, $name: String, $description: String, $modules: [Int]) {
      updateRole (id: $id, name: $name, description: $description, modules: $modules) {
          id
          name
          description
          modules {
              id
              code
              userCode
              name
              groupName
              parentModuleId
              category
              subModules {
                  id
                  code
                  userCode
                  name
                  groupName
                  parentModuleId
                  category
                  subModules {
                      id
                      code
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                  }
              }
          }
      }
    }`;
    
  const variables = {
    id, 
    name,
    modules
  };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(updateRoleResponse({ updateRole: data.updateRole ? true : false, error: null }));
    } else {
      throw 'Role has not been updated';
    }
  } catch (exc) {
    yield put(
      updateRoleResponse({
        error: exc.error,
        updateRole: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/role/redux/sagas",
      "updateRole",
      true,
    );
  }
}

function* deleteRole({ payload }) {
  try {
    const { items } = payload;
    let mutations = [];
    
    items.forEach((item, index) => {
      mutations.push(`Role${index}: deleteRole(id: ${item?.id})`)
    });

    let mutation = `mutation{${mutations.join("")}}`;
  
    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );
    if (response && response.Role0) {
      yield put(deleteRoleResponse({ deleteRole: response, error: null }));
    } else {
      throw 'Error while deleting user';
    }
  } catch (exc) {
    yield put(
      deleteRoleResponse({
        error: exc.error,
        deleteRole: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/role/redux/sagas",
      "deleteRole"
    );
  }
}

function* watchMethods() {
  yield takeEvery(ROLE_SEARCH, searchRoles);
  yield takeEvery(ROLE_GET, getRole);
  yield takeEvery(ROLE_CREATE, createRole);
  yield takeEvery(ROLE_UPDATE, updateRole);
  yield takeEvery(ROLE_DELETE, deleteRole); 
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}