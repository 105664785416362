// Dependencies
import {all, call, fork, put, takeEvery} from "@redux-saga/core/effects";
// Action Types
import {
    SETTINGS_DRIVER_AVATAR,
    SETTINGS_DRIVER_CHANGE_STATUS,
    SETTINGS_DRIVER_CREATE,
    SETTINGS_DRIVER_DELETE,
    SETTINGS_DRIVER_DELETE_DRIVERS_TAG,
    SETTINGS_DRIVER_GET_URL_FILE,
    SETTINGS_DRIVER_IMPORT,
    SETTINGS_DRIVER_LOAD_CSV,
    SETTINGS_DRIVER_SEARCH,
    SETTINGS_DRIVER_SEARCH_TAGS,
    SETTINGS_DRIVER_SEARCH_TRANSACTIONS,
    SETTINGS_DRIVER_UPDATE
} from "Redux/actionTypes";

// Actions
import {
    changeStatusDriverResponse,
    createDriverResponse,
    deleteDriverResponse,
    deleteDriversTagResponse,
    getUrlFileCsvResponse,
    importDriverResponse,
    loadDriverCsvResponse,
    searchDriversResponse,
    searchDriversTagsResponse,
    searchTransactionsResponse,
    updateDriverResponse,
    uploadDriverAvatarResponse
} from "Redux/actions";
import {clientGET} from 'Core/data/RestApi';
import {uploadFile} from "Core/data/FileUpload";

// Helpers
import {clientMutation, clientQuery} from "Core/data/GraphQLApi";
import {endpoints} from "Core/defaultValues";
import {ExceptionManager} from "Core/logManager";

function* searchDrivers({ payload }) {
    const {
        includeInactiveDrivers,
        unassigned,
        openSearch,
        tagIds,
        unitIds,
        limit,
        offset
    } = payload;

    const query = `
    query searchDriversList ($includeInactiveDrivers: Boolean, $unassigned: Boolean, $openSearch: String, $tagIds: [Int], $unitIds: [Int], $limit: Int, $offset: Int) {
        searchDriversList (includeInactiveDrivers: $includeInactiveDrivers, unassigned: $unassigned, openSearch: $openSearch, tagIds: $tagIds, unitIds: $unitIds, limit: $limit, offset: $offset) {
            items {
                driverId
                externalId
                name
                contactInfo
                unitId
                idTimeZone
                tagIds
                unitId
                status
                username
                defaultTab
                avatarFileId
                tempAvatarUrl
                avatarFile {
                    id
                    repositoryId
                    repositoryType
                    repositoryCode
                    repositoryPath
                    fileKey
                    originalName
                }
            }
            total
        }
    }
    `;

    const variables = {
        includeInactiveDrivers,
        unassigned,
        openSearch,
        tagIds:  tagIds || [],
        unitIds:  unitIds || [],
        limit,
        offset
    };

    try {
        const data = yield call(
            clientQuery,
            query,
            variables,
            endpoints.GRAPHQL_GENERAL
        );

        if (data) {
            const { searchDriversList: { items, total } } = data;
            yield put(searchDriversResponse({ items: items || [], total, error: false }));
        }
    } catch (exc) {
        yield put(searchDriversResponse({ items: [], total: 0, error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "searchDrivers"
        );
    }
}

function* createDriver({ payload }) {
    const {
        name,
        externalId,
        groupId,
        tagIds,
        timeZoneId,
        contactInfo,
        unitId,
        username,
        password,
        defaultTab,
        avatarFileId,
        status
    } = payload;

    const mutation = `
        mutation createDriver (
            $name: String!,
            $externalId: String,
            $tagIds: [Int], 
            $timeZoneId: Int!, 
            $contactInfo: String, 
            $unitId: Int, 
            $username: String, 
            $password: String, 
            $defaultTab: String,
            $avatarFileId: Int,
            $defaultTab: String,
            $driverTransitionFrom: String,
            $status: Int
        ) {
            createDriver (
                name: $name, 
                externalId: $externalId, 
                tagIds: $tagIds,
                timeZoneId: $timeZoneId, 
                contactInfo: $contactInfo, 
                unitId: $unitId, 
                username: $username, 
                password: $password, 
                defaultTab: $defaultTab,
                avatarFileId: $avatarFileId,
                driverTransitionFrom: $driverTransitionFrom
                status: $status
            ) {
                contactInfo
                externalId
                companyId
                driverId
                tags {
                    id
                    label
                    color
                    path
                }
                unit {
                    id
                    label
                }
                timeZone {
                    id
                    title
                }
                fsmDriver{
                    username
                    defaultTab
                }
            }
        }
    `;
    const variables = {
        name,
        externalId: externalId || "",
        groupId,
        tagIds,
        timeZoneId,
        contactInfo,
        unitId,
        username,
        password,
        defaultTab,
        avatarFileId,
        driverTransitionFrom:"CloudWebSite",
        status
    };
    try {
        const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL_V2);
        if (data) {
            yield put(createDriverResponse({ driverResponse: data.createDriver }));
        } else {
            throw 'Driver has not been created';
        }
    } catch (exc) {
        let errorKey = "errorMessage";
        if (exc?.error === "Driver contact details, ID or name duplicated") {
            errorKey = "settingsDriver_error_duplicated"
        } else if (exc?.error === "The username for the driver is duplicated.") {
            errorKey = "settingsDriver_error_username_duplicated"
        }

        yield put(createDriverResponse({ driverResponse: { error: errorKey } }));
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "createdriver",
            false
        );
    }
}

function* updateDriver({ payload }) {
    const {
        id,
        name,
        externalId,
        tagIds,
        timeZoneId,
        contactInfo,
        unitId,
        username,
        password,
        defaultTab,
        avatarFileId,
        tempAvatarUrl,
        status
    } = payload;

    const mutation = `
        mutation updateDriver (
            $id: Int!, 
            $name: String, 
            $externalId: String, 
            $tagIds: [Int],
            $timeZoneId: Int, 
            $contactInfo: String, 
            $unitId: Int, 
            $username: String, 
            $password: String, 
            $defaultTab: String,
            $avatarFileId: Int
            $tempAvatarUrl: String
            $driverTransitionFrom: String
            $status: Int
        ) {
            updateDriver (
                id: $id, 
                name: $name, 
                externalId: $externalId, 
                tagIds: $tagIds,
                timeZoneId: $timeZoneId, 
                contactInfo: $contactInfo, 
                unitId: $unitId, 
                username: $username, 
                password: $password, 
                defaultTab: $defaultTab,
                avatarFileId: $avatarFileId
                tempAvatarUrl: $tempAvatarUrl
                driverTransitionFrom: $driverTransitionFrom
                status: $status
            ) {
                name
                externalId
                contactInfo
                companyId
                driverId
                tags {
                    id
                    label
                    color
                    path
                }
                unit {
                    id
                    label
                }
                timeZone {
                    id
                    title
                }
                fsmDriver{
                    username
                    defaultTab
                }
            }
        }
    `;

    const variables = {
        id,
        name,
        externalId: externalId || "",
        tagIds,
        timeZoneId,
        contactInfo,
        unitId,
        username,
        password,
        defaultTab,
        avatarFileId,
        tempAvatarUrl,
        driverTransitionFrom: "CloudWebSite",
        status
    };

    try {
        const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL_V2);
        if (data) {
            yield put(updateDriverResponse({ driverResponse: data.updateDriver }));
        } else {
            throw 'Driver has not been updated';
        }
    } catch (exc) {
        let errorKey = "errorMessage";
        if (exc?.error === "Driver contact details, ID or name duplicated") {
            errorKey = "settingsDriver_error_duplicated"
        } else if (exc?.error === "The username for the driver is duplicated.") {
            errorKey = "settingsDriver_error_username_duplicated"
        }
        
        yield put(updateDriverResponse({ driverResponse: { error: errorKey } }));
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "updatedriver",
            false
        );
    }
}

function* deleteDriver({ payload }) {
    try {
        const { items } = payload;

        let query = `mutation deleteDrivers ($ids: [Int]!) {deleted: deleteDrivers (ids: $ids)}`;
        const ids = items?.map((item) => item.driverId);
        const response = yield call(
            clientMutation,
            query,
            {ids},
            endpoints.GRAPHQL_GENERAL_V2,
        );

        if (response) {
            yield put(deleteDriverResponse({ driverDelete: response, error: false }));
        } else {
            throw 'Error while deleting driver';
        }
    } catch (exc) {
        yield put(deleteDriverResponse({ error: true, driverDelete: null }));
        ExceptionManager(exc,"modules/settings/views/driver/redux/sagas","deleteDriver",true);
    }
}

function* changeStatusDriver({payload}) {
    const {items, status} = payload;
    const mutation = `
        mutation changeStatusDriver ($ids: [Int], $status: Int) {
            changeStatusDriver (
                ids: $ids, 
                status: $status
            )
        }
    `;
    const variables = {
        ids: items.map((driver) => driver.driverId),
        status: status,
    };
    try {
        const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL_V2);
        if (data) {
            yield put(changeStatusDriverResponse({changeStatusResponse: data.createDriver}));
        } else {
            throw 'Driver has not been created';
        }
    } catch (exc) {
        yield put(changeStatusDriverResponse({changeStatusResponse: {error: true}}));
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "changeStatusDriver",
            true
        );
    }
}

function* importDriver({ payload }) {
    try {

        const { fileKey, delimiter } = payload;

        let query = `
            mutation importDrivers ($fileKey: String!, $delimiter: String!) {
                importDrivers (fileKey: $fileKey, delimiter: $delimiter) {
                    stepFunctionId
                    transactionId
                    transactionType
                    processStartDate
                }
            }
      `;

        let data = {
            delimiter,
            fileKey
        }

        const response = yield call(
            clientMutation,
            query,
            data,
            endpoints.GRAPHQL_GENERAL,
        );

        if (response && response.importDrivers) {
            yield put(importDriverResponse({ importDriver: response, error: false }));
        } else {
            yield put(
                importDriverResponse({ error: true, importDriver: null })
            );
        }
    } catch (exc) {
        yield put(
            importDriverResponse({ error: true, importDriver: null })
        );
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "importDriver",
            true
        );
    }
}

function* loadFileCsv({ payload }) {
    const { Url } = payload;

    try {
        const result = yield call(clientGET, '', {}, Url, false);

        if (result) {
            yield put(loadDriverCsvResponse({ error: false, file: result }));
        }
    } catch (exc) {
        // catch throw
        yield put(loadDriverCsvResponse({ error: true, file: null }));
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "loadFileCsv",
            true
        );
    }

}

function* getUrlFileAttachment({ payload }) {
	try {
		const { id } = payload;
		let data = yield call(
			clientQuery,
			`query (
        $id: Int!
      ){
        downloadAttachmentUrl: downloadAttachmentUrl(
          id: $id
        )
      }`,
			{
				id
			},
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.downloadAttachmentUrl && data.downloadAttachmentUrl) {
			yield put(getUrlFileCsvResponse(data.downloadAttachmentUrl));
		} else throw 'There are not url attachment';
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(
			exc,
			'modules/settings/views/driver/redux/sagas',
			'getUrlFileAttachment',
		);
		yield put(getUrlFileCsvResponse(null));
	}
}

function* searchDriverTransaction({ payload }) {
    try {
        const {
            transactionId,
            nextToken
        } = payload;

        const query = `
                query searchTransactions ($transactionId: String!, $nextToken: String) {
                    searchTransactions (transactionId: $transactionId, nextToken: $nextToken) {
                        items {
                            Id
                            TransactionId
                            Type
                            State
                            ErrorMessage
                            NewId
                            Data
                            CreatedBy
                            CreatedOn
                            ProcessedOn
                        }
                        nextToken
                    }
                }
          `;

        const response = yield call(
            clientQuery,
            query,
            {
                transactionId,
                nextToken
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.searchTransactions) {
            throw "Error while getting Transactions";
        }
        let searchTransactions = response.searchTransactions;
        yield put(searchTransactionsResponse({ items:searchTransactions, error: false }));
    } catch (exc) {
        yield put(searchTransactionsResponse({ items: [], error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "searchDriverTransaction"
        );
    }
}

function* searchDriversTags({ payload }) {
    const {
        openSearch,
        limit,
        offset
    } = payload;

    const query = `
        query searchTagsInline ($openSearch: String, $entityName: String!, $limit: Int, $offset: Int) {
            searchTagsInline (openSearch: $openSearch, entityName: $entityName, limit: $limit, offset: $offset) {
               items {
                    id
                    label
                    color
                    disabled
                    driversCount
               }
               total
            }
        }
    `;

    try {
        const response = yield call(
            clientQuery,
            query,
            {
                openSearch,
                entityName: "Driver",
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (response) {
            const { searchTagsInline: { items, total } } = response;
            yield put(searchDriversTagsResponse({ items: items, total: total, error: false }));
        }
    } catch (exc) {
        yield put(searchDriversTagsResponse({ items: [], total: 0, error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "searchDriversTags"
        );
    }
}

function* deleteDriversTag({ payload }) {
    try {
        const { items } = payload;
        let mutations = [];

        items.forEach((item, index) => {
            mutations.push(
                `tag${index}: updateTagAssignment(
                    tagId: ${item?.tagId}
                    entityName: Driver
                    entityIds: [${item?.entityIds?.join(", ")}]
                    action: Delete
                )
            `);
        });

        let mutation = `mutation{${mutations.join("")}}`;

        const response = yield call(
            clientMutation,
            mutation,
            {},
            endpoints.GRAPHQL_GENERAL,
        );

        if (response && response.tag0) {
            yield put(deleteDriversTagResponse({ response: response.tag0 }));
        } else {
            throw 'Error while deleting drivers from tags';
        }
    } catch (exc) {
        yield put(
            deleteDriverResponse({ response: { error: true } })
        );
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "deleteDriversTag",
            true
        );
    }
}


function* createDriverAvatar({ payload }) {
    const { file,fileKeyDelete } = payload;

    try {

        if(fileKeyDelete){
            const mutation = `mutation deleteAttachment ($id: Int!) {deleteAttachment (id: $id)}`;
            const data = yield call(clientMutation, mutation, { id: fileKeyDelete }, endpoints.GRAPHQL_GENERAL_V2);
        }

        if(file){
            let extension = file?.name.split(".").pop();
            let presignedUrlResponse = yield call(
                clientQuery,
                `query(
                        $repositoryCode: String
                        $fileExtension: String
                    ) {
                        Data: getPresignedUrl(
                            repositoryCode: $repositoryCode,
                            fileExtension: $fileExtension
                        ) {
                            repositoryCode
                            fileKey
                            url
                        }
                    }`,
                {
                repositoryCode: endpoints.REPOSITORY_CODE,
                fileExtension: extension,
                },
                endpoints.GRAPHQL_GENERAL_V2
            );


            if (!presignedUrlResponse || !presignedUrlResponse.Data) {
                throw "There are a problem uploading the File";
            }

            yield uploadFile(presignedUrlResponse.Data.url, file);

            //Register file
            let registerAttachmentResult = yield call(
                clientMutation,
                `mutation ($repositoryCode: String!, $fileKey: String!, $originalName: String){
                    attachment: registerAttachment(repositoryCode: $repositoryCode, fileKey: $fileKey, originalName: $originalName){
                        id
                        repositoryId
                        repositoryType
                        repositoryCode
                        repositoryPath
                        fileKey
                        originalName
                    }
                }`,
                {
                    repositoryCode: presignedUrlResponse.Data.repositoryCode,
                    fileKey: presignedUrlResponse.Data.fileKey,
                    originalName: file?.name,
                },
                endpoints.GRAPHQL_GENERAL
            );

            if (registerAttachmentResult) {
                yield put(uploadDriverAvatarResponse(registerAttachmentResult.attachment));
            } else {
                throw "There are a problem register the File";
            }
        }else{
            yield put(uploadDriverAvatarResponse({id:null,fileKey:fileKeyDelete}));

        }

    } catch (exc) {
        yield put(
            uploadDriverAvatarResponse({ })
        );
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "createDriverAvatar",
            true
        );
    }
}

function* watchMethods() {
    yield takeEvery(SETTINGS_DRIVER_SEARCH, searchDrivers);
    yield takeEvery(SETTINGS_DRIVER_CREATE, createDriver);
    yield takeEvery(SETTINGS_DRIVER_UPDATE, updateDriver);
    yield takeEvery(SETTINGS_DRIVER_DELETE, deleteDriver);
    yield takeEvery(SETTINGS_DRIVER_IMPORT, importDriver);
    yield takeEvery(SETTINGS_DRIVER_CHANGE_STATUS, changeStatusDriver);
    yield takeEvery(SETTINGS_DRIVER_LOAD_CSV, loadFileCsv);
    yield takeEvery(SETTINGS_DRIVER_SEARCH_TRANSACTIONS, searchDriverTransaction);
    yield takeEvery(SETTINGS_DRIVER_GET_URL_FILE, getUrlFileAttachment);
    yield takeEvery(SETTINGS_DRIVER_SEARCH_TAGS, searchDriversTags);
    yield takeEvery(SETTINGS_DRIVER_DELETE_DRIVERS_TAG, deleteDriversTag);
    yield takeEvery(SETTINGS_DRIVER_AVATAR, createDriverAvatar);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}
