import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback, Fragment} from 'react';
import './table.scss';
import _ from 'lodash'
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Checkbox,
    Container,
    Paper,
    Grid,
    Icon,
    Collapse
} from '@mui/material';
import {LinearProgress, Button, DragDropList, Checkbox as CustomCheckbox} from 'Components'
import Pagination from '../Pagination';
import TableHeader from './.components/header'
import AreaMenu, { Area, Menu } from 'Components/AreaMenu';
import { Waypoint } from 'react-waypoint';
import { NotificationManager } from 'Components/Notifications';
import {getInfoReportFilter} from "Modules/reports/views/main/components/reportsTab";

const TableView = forwardRef((props, ref) => {

    const { keyColumn, columns = [], topheight, data = [], grouping, groupingValueToCompare,
        groupingArea, searchText, onSelectedItems, hidePagination, exportOrientation,
        exportName, onChangeColumns, hideAllInPagination, fixHeader = true, infiniteScroll = false, nextToken,
        height, disableItemSelection = false, onCollapse, exportServerSide, getDataToExport, messageNoData,
        onChangeSort,exportTitle, className, hidePerPage, selectAllRows, valuesPerPage, order, hideTotal,
        sort: newSort, rowsPerPage: newRowsPerPage,
        customViewReports = null,
    } = props

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(infiniteScroll ? 25 : selectAllRows ? selectAllRows : 10);
    //const [columnsView, setColumnsView] = useState([...columns])
    const [sort, setSort] = useState({ key: 'label', order: order ? order : 'desc' });
    const [selectedItems, setSelectedItems] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [executeChangeDisplayData, setExecuteChangeDisplayData] = useState(true);
    const [eventEditReport, setEventEditReport] = useState([]);


    useEffect(() => {
        if (props.data && props.data.length > 0 && page == 0) {
            setPage(props.defaultPage || 1)
        }

        if (props.data && props.data.length > 0 && props?.onChangeDisplayData && executeChangeDisplayData) {
            setExecuteChangeDisplayData(false);
            callChangeDisplayData()
        }
    }, [props.data]);

    useEffect(() => {
        callChangeDisplayData()
    }, [searchText]);

    useEffect(() => {
        if (newSort && newSort?.key) {
            setSort(newSort);
        }
    }, [newSort]);

    useEffect(() => {
        if (newRowsPerPage) {
           if (newRowsPerPage != rowsPerPage) setRowsPerPage(newRowsPerPage);
        }
    }, [newRowsPerPage]);

    let currentGroup
    let sortGroup
    if (grouping)
        sortGroup = { key: groupingValueToCompare, order: 'asc' }

    useImperativeHandle(ref, () => ({
        selectedItems: selectedItems,
        exportPDF: exportPDF,
        exportCSV: exportCSV,
        setPage: setPage,
        setSelectedItems: setSelectedItems,
        columns: columns,
        page: page,
        rowsPerPage: rowsPerPage,
        setRowsPerPage: setRowsPerPage,
        setSort: setSort,
        data: filterDataPaginate || []
    }))

    const callChangeDisplayData = () => {
        if (props?.onChangeDisplayData) {
            setTimeout(() => {
                props.onChangeDisplayData(ref?.current?.data)
            }, 500)
        }
    }

    const [filterData, total] = useMemo(() => {
        if (infiniteScroll && nextToken === undefined) {
            return [data, props.total];
        }

        if (infiniteScroll) {
            return [data, 0];
        }

        if (props.onChangePage) {
            return [data, props.total];
        }

        let sorts = [];
        let viewData = data
        let total = data.length


        if (sortGroup) sorts.push(sortGroup)
        if (sort) sorts.push(sort)

        if (searchText && typeof searchText !== 'undefined' && searchText.length > 0) {
            viewData = data.filter(x => {
                let result = false;
                columns.forEach(col => {
                    if (col.disableSearch == undefined || !col.disableSearch) {
                        let value;
                        if (col?.sortValue) {
                            value = getClearValue(col?.sortValue(x));
                        } else {
                            value = getClearValue(x[col.key]);
                        }
                        if (value && value?.toString().indexOf(searchText.toLowerCase()) >= 0) {
                            result = true;
                        }
                    }
                });

                return result
            })

            total = viewData.length
        }

        if (sorts && sorts.length > 0) viewData = multipleSort(viewData || [], sorts || [], columns || []);

        return [viewData, total];
    });

    const filterDataPaginate = useMemo(() => {
        if (infiniteScroll || props.onChangePage) {
            return filterData;
        }

        let currentPage = page - 1;
        if (!hidePagination && rowsPerPage != 'ALL') {
            let valuesPaginate = filterData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
            return valuesPaginate;
        }

        return filterData;
    });

    const exportCSV = useCallback(() => {
        const headers = columns.filter(x => x.export && x.export)

        let csvContent = exportTitle?exportTitle+ '\n':'';
        csvContent+= `${headers.map(x => x.title).join(',')}` + '\n';

        let rows = (getDataToExport ? getDataToExport(filterData, 'csv') : filterData);
        if (selectedItems?.length) rows = selectedItems;

        rows.forEach((row, index) => {
            //its necesary format the text because if possible has a line breaks or ,
            let itemValues = [];
            if (getDataToExport) {
                itemValues = row;
            } else {
                itemValues = headers.map((col) => {
                    let value = col.exportValue && col.exportValue(row) || row[col.key] || "";
                    value = value.toString().replaceAll(/(\r\n|\n|\r)/gm, "") || ""
                    if (value.includes(","))
                        value = `"${value}"`;

                    return value
                });
            }

            let dataString = itemValues.join(',');
            csvContent += index < rows.length ? dataString + '\n' : dataString;
        });



        let fileName = `${exportName || 'exported-data'}.csv`;
        let mimeType = 'text/csv;encoding:utf-8;charset=UTF-8';
        let a = document.createElement('a');

        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) {
            // IE10
            navigator.msSaveBlob(
                new Blob([csvContent], {
                    type: mimeType,
                }),
                fileName,
            );
        } else if (URL && 'download' in a) {
            //html5 A[download]
            a.href = URL.createObjectURL(
                new Blob([csvContent], {
                    type: mimeType,
                }),
            );
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            location.href = 'data:application/octet-stream,' + encodeURIComponent(csvContent); // only this mime type is supported
        }
    });

    const exportPDF = useCallback(() => {
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = exportOrientation || 'portrait'; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);

        jsPDF.autoTableSetDefaults({
            headStyles: {
                font: "arial",
                fillColor: "white",
                textColor: "black",
                halign: "center",
                fontStyle: "normal",
                fontSize: 10,
                lineColor: "black",
                lineWidth: {top: 1, right: 1, bottom: 1, left: 1}
            },
            margin: { left: 5,right: 5 }
        }, doc)

        let rows = filterData;
        if (selectedItems?.length) rows = selectedItems;

        const headers = columns.filter(x => x.show && x.export);
        let content = {
            pageBreak: 'auto',
            startY: exportTitle?40:20,
            head: [headers.map(x => x.title)],
            //its necesary loop the data to assign styles for wrap text when is a long value
            body: getDataToExport ? getDataToExport(rows, 'pdf') : rows.map((row) => {
                return headers.map((col) => {
                    return { content: col.exportValue && col.exportValue(row) || row[col.key] || "", styles: { cellWidth: 'auto', overflow: 'linebreak', minCellWidth: 50,textColor:col.exportColorValue?col.exportColorValue(row):'black' } }
                })
            }),
        };

        if(exportTitle)
            doc.text(40, 15,exportTitle);

        doc.autoTable(content);
        doc.save(`${exportName || 'export'}.pdf`);
    });

    return (
        <div style={{ height: (height ? height : (topheight && `calc(100vh - ${topheight}px)` || '100%')), width: '100%' }}>
            <Grid item className="table_component">
                <Fragment>
                    {
                        props.loading &&
                        <LinearProgress color="primary" />
                    }
                    <TableContainer>
                        <Table
                            className={className ? className : ''}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                            stickyHeader={fixHeader}
                        >
                            <TableHeader
                                {...props}
                                columnsView={columns.filter(x => x.show)}
                                onChangeColumns={(newColumns) => onChangeColumns([...newColumns])}
                                sort={sort}
                                page={page}
                                dataItems={filterDataPaginate}
                                onChangeSort={(newSort) => {
                                    setSort(newSort);
                                    callChangeDisplayData();
                                    if (onChangeSort) onChangeSort(newSort);
                                }}
                                selectedItems={selectedItems}
                                onChageSelectedItems={(newSelectedtItems) => {
                                    setSelectedItems(newSelectedtItems)
                                    onSelectedItems && onSelectedItems(newSelectedtItems, keyColumn)
                                }}
                            />

                            <TableBody>
                                {
                                    data?.length === 0 && !props.loading && messageNoData && (
                                        <TableRow>
                                            <TableCell colSpan={columns.length && !disableItemSelection ? columns.length + 1 : columns.length}>
                                                {messageNoData}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    filterDataPaginate.map((row, index) => {
                                        let printGroup = false
                                        if (grouping) {
                                            let newGroup = row[groupingValueToCompare]
                                            printGroup = !_.isEqual(newGroup, currentGroup)
                                            currentGroup = printGroup ? newGroup : currentGroup
                                        }
                                        const dataIndex = data[index];
                                        const useCheckReportTitle = dataIndex?.params && customViewReports? getInfoReportFilter(dataIndex, customViewReports) : "";
                                        return (
                                            <Fragment key={index}>
                                                {
                                                    printGroup &&
                                                    <Fragment>
                                                        <TableRow>
                                                            <TableCell colSpan={columns.filter(x => x.show).length + 1} style={{padding: 0}}>
                                                                {
                                                                    groupingArea
                                                                        ? groupingArea(currentGroup, row)
                                                                        : currentGroup.toString()
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                }

                                                <TableRow id={'unique-tr-' + index}>
                                                    {
                                                        !disableItemSelection &&
                                                        <TableCell padding="checkbox" id={'unique-tr-' + index}>
                                                            { (useCheckReportTitle !== '' && !disableItemSelection) ? (
                                                                <>
                                                                    <div className="checkbox-report-table-div">
                                                                        <Checkbox
                                                                            style={{ color: "black", zIndex: 0 }}
                                                                            className={'checkbox-table-select'}
                                                                            checked={selectedItems.findIndex(x => keyColumn && x[keyColumn] == row[keyColumn] || x == row) > -1}
                                                                            onChange={() => {
                                                                                let newSelectedItems = [...selectedItems];
                                                                                let index = newSelectedItems.findIndex(x => keyColumn && x[keyColumn] == row[keyColumn] || x == row)
                                                                                if (index > -1)
                                                                                    newSelectedItems.splice(index, 1)
                                                                                else
                                                                                    newSelectedItems.push(row)

                                                                                setSelectedItems(newSelectedItems)
                                                                                onSelectedItems && onSelectedItems(newSelectedItems, keyColumn)
                                                                            }}
                                                                            inputProps={{'aria-label': 'select all desserts'}}
                                                                            size="small"
                                                                        />
                                                                        <div className='report-table-info-tooltip'>
                                                                            <Button
                                                                                type="icon"
                                                                                icon="info"
                                                                                placement="right"
                                                                                tooltip={useCheckReportTitle}
                                                                                tooltipType={"report"}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            ) : (
                                                            <>
                                                                <Checkbox
                                                                    style={{ color: "black", zIndex: 0 }}
                                                                    className={'checkbox-table-select'}
                                                                    checked={selectedItems.findIndex(x => keyColumn && x[keyColumn] == row[keyColumn] || x == row) > -1}
                                                                    onChange={() => {
                                                                        let newSelectedItems = [...selectedItems];
                                                                        let index = newSelectedItems.findIndex(x => keyColumn && x[keyColumn] == row[keyColumn] || x == row)
                                                                        if (index > -1)
                                                                            newSelectedItems.splice(index, 1)
                                                                        else
                                                                            newSelectedItems.push(row)


                                                                        setSelectedItems(newSelectedItems)
                                                                        onSelectedItems && onSelectedItems(newSelectedItems, keyColumn)
                                                                    }}
                                                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                                                    size="small"
                                                                />
                                                            </>)
                                                            }
                                                        </TableCell>
                                                    }

                                                    {
                                                        onCollapse &&
                                                        <TableCell>
                                                            {
                                                                row?.children > 0 &&
                                                                <Button
                                                                    type="icon"
                                                                    icon={expanded.includes(row[keyColumn]) ? "arrow_drop_down" : "arrow_right"}
                                                                    onClick={() => {
                                                                        if (expanded.includes(row[keyColumn])) {
                                                                            setExpanded([...expanded.filter((e) => e !== row[keyColumn])]);
                                                                        } else {
                                                                            setExpanded([...expanded, ...[row[keyColumn]]]);
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </TableCell>
                                                    }

                                                    {
                                                        columns.filter(x => x.show).map(col => {
                                                            return (
                                                                <TableCell key={col.key} id={'unique-tr-' + index}>
                                                                    {
                                                                        col.render
                                                                            ? col.render(row)
                                                                            : row[col.key]?.toString()
                                                                    }
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>

                                                {
                                                    onCollapse &&
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={columns.length + 1}
                                                            style={{padding: 0}}
                                                        >
                                                            <Collapse
                                                                in={expanded.includes(row[keyColumn])}
                                                                timeout="auto"
                                                                unmountOnExit
                                                            >
                                                                {onCollapse(row)}
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }


                                {
                                    infiniteScroll &&
                                    <TableRow>
                                        <TableCell style={{textAlign: 'center', padding: 10}} colSpan={columns.filter(x => x.show).length + 1} styles={{padding: 0}}>
                                            <Waypoint
                                                onEnter={() => {
                                                    if (nextToken) {
                                                        if (props.onChangePage) props.onChangePage();
                                                        //setSelectedItems([]);
                                                    } else if (nextToken === undefined && total) {
                                                        let newPage = page + 1;
                                                        if (filterDataPaginate.length < total) {
                                                            setPage(newPage);
                                                            if (props.onChangePage) props.onChangePage(newPage, rowsPerPage);
                                                            callChangeDisplayData();
                                                            //setSelectedItems([]);
                                                        }
                                                    }
                                                }}
                                            />

                                            {
                                                (!props.moreRowsAvailable && !props.loading) &&
                                                <div className="no-more-items">
                                                    {props.noMoreItemsMessage || 'There are no more items'}
                                                </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
            </Grid>

            {
                (!hidePagination && !infiniteScroll) &&
                <Grid item xs>
                    <div
                        style={{
                            padding: '5px 0px 0px 0px'
                        }}
                    >
                        <Pagination
                            page={page}
                            rowsPerPage={rowsPerPage}
                            count={total}
                            pagination
                            hideTotal={hideTotal}
                            hidePerPage={hidePerPage}
                            valuesPerPage={valuesPerPage}
                            selectAllRows={selectAllRows}
                            hideAllInPagination={hideAllInPagination}
                            onChangePage={(event, value) => {
                                setPage(value);
                                if (props.onChangePage) props.onChangePage(value, rowsPerPage);
                                callChangeDisplayData();
                                setSelectedItems([]);
                                onSelectedItems && onSelectedItems([])
                            }}
                            onChangeRowsPerPage={(value) => {
                                setRowsPerPage(value);
                                setPage(1);
                                if (props.onChangeRowsPerPage) props.onChangeRowsPerPage(page, value);
                                callChangeDisplayData();
                                setSelectedItems([]);
                                onSelectedItems && onSelectedItems([])
                            }}
                        />
                    </div>
                </Grid>
            }
        </div>
    )
})

export default TableView

export const TableButtons = (props) => {
    const {
        messages,
        tableRef,
        columns,
        onChangeColumns,
        buttons = [
            'export',
            'orderColumn'
        ],
        onExport,
        disabled
    } = props;

    const MessageExportSuccess = () => {

        return NotificationManager.success(
            messages['exportSuccess'],
            messages['success'],
            3000,
            null,
            null,
            'success',
        );

    };

    const availableButtons = {
        export: (key) => {
            return (
                <AreaMenu key={key} id="exportTable" clickClose>
                    <Area>
                        <Button
                            type="icon"
                            icon="get_app"
                            tooltip={messages['export']}
                            onClick={() => {
                                //tableRef.current.exportCSV()
                            }}
                            disabled={disabled}
                        />
                    </Area>
                    {
                        disabled ?
                            <></>
                            :
                            <Menu className="menu-columns">
                                <ul>
                                    <li
                                        onClick={() => {
                                            if (onExport) {
                                                onExport('Csv');
                                            } else {
                                                MessageExportSuccess();
                                                tableRef.current.exportCSV();
                                            }
                                        }}
                                    >
                                        {messages ? messages['exportCSV'] : 'Export CSV'}
                                    </li>
                                    <li
                                        onClick={() => {
                                            if (onExport) {
                                                onExport('Pdf');
                                            } else {
                                                MessageExportSuccess();
                                                tableRef.current.exportPDF();
                                            }
                                        }}
                                    >
                                        {messages ? messages['exportPDF'] : 'Export PDF'}
                                    </li>
                                </ul>
                            </Menu>
                    }
                </AreaMenu>
            )
        },
        orderColumn: (key) => {
            return (
                <AreaMenu key={key} id="columnsOrder" noHideOnBlur>
                    <Area>
                        <Button
                            type="icon"
                            icon="view_column"
                            tooltip={messages['changeColumnOrder']}
                            onClick={() => {
                                //tableRef.current.exportCSV()
                            }}
                        />
                    </Area>
                    <Menu style={{width: 300}}>
                        <div>
                            <DragDropList
                                items={columns}
                                keyName="key"
                                itemRenderer={(item) => {
                                    return (
                                        <div style={{display: 'flex',paddingLeft:12}}>
                                            <CustomCheckbox
                                                id={item.key}
                                                style={{color: "black"}}
                                                label={item.title}
                                                value={item.show}
                                                onChange={(checked) => {
                                                    let index = columns.findIndex(x => x == item)
                                                    let newColumns = [...columns]
                                                    newColumns[index].show = checked;
                                                    onChangeColumns(newColumns)
                                                }}
                                                inputProps={{'aria-label': 'select all desserts'}}
                                            />
                                        </div>
                                    );
                                }}
                                onDragEnd={(result) => {

                                    if (!result.destination) return;

                                    const newColumns = [...columns];

                                    const [reorderedItem] = newColumns.splice(result.source.index, 1);
                                    newColumns.splice(result.destination.index, 0, reorderedItem);

                                    onChangeColumns(newColumns);
                                }}
                            />
                        </div>
                    </Menu>
                </AreaMenu>
            );
        }
    };

    return (
        <div style={{display: 'flex'}}>
            {
                buttons.map((button, key) => {
                    return availableButtons[button](key);
                })
            }
        </div>
    )
}

const getClearValue = (value) => {
    if (!value) return "";

    if (typeof value == 'undefined') return "";

    if (Array.isArray(value) || typeof value === 'object') return "";

    if (isNaN(value)) return value.toLowerCase();

    return value
}

const normalizeMixedDataValue = (value) => {
    const padding = "000000000000000";

    if (!value) return "";
    // Loop over all numeric values in the string and
    // replace them with a value of a fixed-width for
    // both leading (integer) and trailing (decimal)
    // padded zeroes.
    value = value?.toString()?.replace(
        /(\d+)((\.\d+)+)?/g,
        ($0, integer, decimal, $3) => {

            // If this numeric value has "multiple"
            // decimal portions, then the complexity
            // is too high for this simple approach -
            // just return the padded integer.
            if (decimal !== $3) {
                return (
                    padding.slice(integer.length) +
                    integer +
                    decimal
                );
            }

            decimal = (decimal || ".0");

            return (
                padding.slice(integer.length) +
                integer +
                decimal +
                padding.slice(decimal.length)
            );

        }
    );

    return value;
}

const multipleSort = (data, sorts, columns = []) => {
    const newSorts = sorts;

    return [...data].sort((a, b) => {
        let i = 0, result = 0;
        while (i < newSorts.length && result === 0) {
            let column = columns?.find(column => column?.key === newSorts[i]?.key);
            let order = (newSorts[i].order == 'asc' ? 1 : -1);

            let valueA;
            let valueB;
            if (column && column?.sortValue) {
                valueA = getClearValue(column?.sortValue(a));
                valueB = getClearValue(column?.sortValue(b));
            } else {
                valueA = getClearValue(a[newSorts[i].key]);
                valueB = getClearValue(b[newSorts[i].key]);
            }

            if (column?.alphanumeric) {
                valueA = normalizeMixedDataValue(valueA);
                valueB = normalizeMixedDataValue(valueB);
            }

            result = order * (valueA < valueB ? -1 : (valueA > valueB ? 1 : 0));
            i++;
        }

        return result;
    })

}
