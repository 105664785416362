import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  VIDEO_DETAIL_GET_AVAILABLE_MEDIA,
  VIDEO_DETAIL_GET_CLIP_REQUEST,
  VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA,
  VIDEO_DETAIL_UPDATE_CLIP_REQUEST,
  VIDEO_DETAIL_DELETE_CLIPS_REQUEST,
  VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA,
  VIDEO_DETAIL_ADD_NOTE,
  VIDEO_DETAIL_DELETE_NOTES
} from 'Redux/actionTypes';

import {
  getMediaDetailResponse,
  updateClipRequestResponse,
  updateAvailableMediaResponse,
  updateEvent,
  videoMainUpdateClipRequest,
  deleteClipsRequestResponse,
  deleteAvailableMediaResponse,
  addNoteAvailableMediaResponse,
  deleteNotesAvailableMediaResponse
} from 'Redux/actions';

function* getAvailableMediaRequest({ payload }) {
  try {
    const { data: body } = payload;
    const { id, deviceId } = body;

    let data = yield call(
      clientQuery,
      `query ($readingId: Float!, $deviceId: String!) {
        Media: getAvailableMedia (readingId: $readingId, deviceId: $deviceId) {
          reading {
            id
            deviceId
            latitude
            longitude
            heading
            eventCode
            eventName
            address
            driverId
            driverName
            ecuEngineOdometer
            ecuSpeed
            ecuEngineHours
            engineHours
            ignitionStatus
            engineOdometer
            satelliteCount
            signal
            speed
            speedLimit
            unitTime
            state
            unitType
            zip
            hDop
            vDop
            deviceData
            notes
            starred
            mediaDeleted
            deviceReadingExtraFields {
              id
              readingId
              fieldName
              jsonValue
              createdOn
            }
          }
          unit {
            driverName
            label
            timeZone {
              id
              name
            }
            tags {
              id
              label
              color
              hasChildren
            }
          }
          linkedDevice {
            id
            esn
            deviceModelId
            deviceModelName
            manufacturerId
            manufacturerName
            hasVideo
          }
        }
      }`,
      {
        readingId: id,
        deviceId: deviceId
      },
      endpoints.GRAPHQL_GENERAL_V2,
    );

    if (data && data.Media) {
      yield put(getMediaDetailResponse(data.Media));
    } else {
      throw 'Not media data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'getAvailableMediaRequest');
    yield put(getMediaDetailResponse({ error: true }));
  }
}

function* getClipRequestRequest({ payload }) {
  try {
    const { data: body } = payload;
    const { id } = body;

    let data = yield call(
      clientQuery,
      `query ($id: String!) {
        Clip: getClipRequest (id: $id) {
          id
          accountId
          createdOn
          deviceId
          esn
          label
          status
          time
          tripID
          clipID
          rotate
          url
          startDate
          endDate
          notes
          starred
          errorMsg
          deviceModelId
          deviceModelName
          isDeleted
          thumbnailUrl
          details
          unit {
            driverName
            driverExternalId
            label
          }
        }
      }`,
      {
        id: id
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.Clip) {
      yield put(getMediaDetailResponse(data.Clip));
    } else {
      throw 'Not clip request data data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'getClipRequestRequest');
    yield put(getMediaDetailResponse({ error: true }));
  }
}

function* updateClipRequestRequest({ payload }) {
  const { data: body } = payload;
  try {
    const data = yield call(
      clientMutation,
      `
      mutation updateClipRequest (
        $id: String!, 
        $notes: String,
        $starred: Boolean
      ) {
        ClipRequest: updateClipRequest (id: $id, notes: $notes, starred: $starred) {
          id
          notes
          starred
        }
      } `,
      body,
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.ClipRequest) {
      yield put(updateClipRequestResponse(data.ClipRequest));
      yield put(videoMainUpdateClipRequest(data.ClipRequest));
    } else {
      throw 'Error while adding note to clip request';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'updateClipRequestRequest');
    yield put(updateClipRequestResponse({ id: body?.id, error: true }));
  }
}

function* updateAvailableMediaRequest({ payload }) {
  const { data: body } = payload;
  try {
    const data = yield call(
      clientMutation,
      `
      mutation updateDeviceReading (
        $id: String!,
        $deviceId: String!,
        $notes: String,
        $starred: Boolean,
        $mediaDeleted: Boolean
        $videoReview: inputVideoReview
      ) {
        AvailableMedia: updateDeviceReading (id: $id, deviceId: $deviceId, notes: $notes, starred: $starred, mediaDeleted: $mediaDeleted, videoReview: $videoReview) {
          id
          notes
          starred
          mediaDeleted,
          deviceData
        }
      } `,
      {
        ...body,
        id: body?.id,
        starred: body?.starred || false,
        mediaDeleted: body?.mediaDeleted || false,
        videoReview: body?.videoReview || null,
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.AvailableMedia) {
      yield put(updateAvailableMediaResponse({ ...data.AvailableMedia, notes: body?.notes }));
      yield put(updateEvent({ ...data.AvailableMedia, notes: body?.notes }));
    } else {
      throw 'Error while adding note to available media';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'addNoteAvailableMediaRequest');
    yield put(updateAvailableMediaResponse({ id: body?.id, error: true }));
  }
}

function* deleteClipsRequestRequest({ payload }) {
  try {
    const { data: body } = payload;
    let mutations = [];
    body?.items?.forEach((item, index) => {
      mutations.push(`
        Clip${index}: deleteClipRequest(id:"${item?.id}")
      `)
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (response && response.Clip0) {
      yield put(deleteClipsRequestResponse(response));
    } else {
      throw 'Error while deleting clips request';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'deleteClipRequestRequest');
    yield put(deleteClipsRequestResponse({ error: true }));
  }
}

function* deleteAvailableMediaRequest({ payload }) {
  try {
    const { data } = payload;
    const ids = [];
    const devicesId = []
    data?.items?.forEach(item => {
      ids.push(item.id)
      devicesId.push(item.deviceId)
    });

    const res = yield call(
      clientMutation,
      `
        mutation deleteAvailableMedia ($rowsId: [String]!, $devicesId: [String]!) {
        deleteAvailableMedia (rowsId: $rowsId, devicesId: $devicesId)
      }`,
      {
        rowsId : ids,
        devicesId
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data?.notes?.length) {
      let mutations = [];
      data?.notes.forEach((note, index) => {
        mutations.push(`
              createDeviceReadingExtraField${index}: createDeviceReadingExtraField(
                rowId: "${note?.rowId}",
                deviceId: "${note?.deviceId}",
                fieldName: "${note?.fieldName}",
                jsonValue: ${JSON.stringify(note?.jsonValue)}
              ){
                id
                createdOn
                createdBy
                updatedOn
                updatedBy
              }
          `);
      });
      let mutation = `mutation{${mutations.join("")}}`;
      yield call(
        clientMutation,
        mutation,
        {},
        endpoints.GRAPHQL_GENERAL_V2,
      );
    }

    if (res && res.deleteAvailableMedia) {
      yield put(deleteAvailableMediaResponse(res.deleteAvailableMedia));
    } else {
      throw 'Error while deleting available media';
    }

  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'deleteAvailableMediaRequest');
    yield put(deleteAvailableMediaResponse({ error: true }));
  }
}

function* addNoteAvailableMediaRequest({ payload }) {
  try {
    const { data } = payload;

    let query = `
      mutation createDeviceReadingExtraField (
        $rowId: String!
        $deviceId: String!
        $fieldName: String!
        $jsonValue: String!
      ) {
        note: createDeviceReadingExtraField (rowId: $rowId, deviceId: $deviceId, fieldName: $fieldName, jsonValue: $jsonValue) {
          id
          readingId
          fieldName
          jsonValue
          createdOn
        }
      } 
    `;

    if (data?.id) {
      query = `
        mutation updateDeviceReadingExtraField (
          $id: Int!
          $rowId: String
          $deviceId: String
          $fieldName: String
          $jsonValue: String
        ) {
          note: updateDeviceReadingExtraField (id: $id, rowId: $rowId, deviceId: $deviceId, fieldName: $fieldName, jsonValue: $jsonValue) {
            id
            readingId
            fieldName
            jsonValue
            createdOn
          }
        } 
      `;
    }

    const response = yield call(
      clientMutation,
      query,
      data,
      endpoints.GRAPHQL_GENERAL_V2,
    );

    if (response && response.note) {
      yield put(addNoteAvailableMediaResponse(response.note));
    } else {
      throw 'Error while adding note to available media';
    }

  } catch (exc) {
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'addNoteAvailableMediaRequest');
    yield put(addNoteAvailableMediaResponse({ error: true }));
  }
}

function* deleteNotesAvailableMediaRequest({ payload }) {
  try {
    const { data } = payload;
    const ids = [];
    data?.items?.forEach(item => {
      ids.push(item.id)
    });

    const response = yield call(
      clientMutation,
      `
      mutation deleteDeviceReadingExtraFields (
        $ids: [Int]!
      ) {
        note: deleteDeviceReadingExtraFields (ids: $ids)
      } `,
      { ids },
      endpoints.GRAPHQL_GENERAL_V2,
    );

    if (response && response.note) {
      yield put(deleteNotesAvailableMediaResponse(response.note));
    } else {
      throw 'Error while deleting notes available media';
    }

  } catch (exc) {
    ExceptionManager(exc, 'modules/modules/video/views/videoDetail/redux/saga', 'deleteNotesAvailableMediaRequest');
    yield put(deleteNotesAvailableMediaResponse({ error: true }));
  }
}

function* watchGetAvailableMediaRequest() {
  yield takeEvery(VIDEO_DETAIL_GET_AVAILABLE_MEDIA, getAvailableMediaRequest);
}

function* watchGetClipRequestRequest() {
  yield takeEvery(VIDEO_DETAIL_GET_CLIP_REQUEST, getClipRequestRequest);
}

function* watchUpdateClipRequestRequest() {
  yield takeEvery(VIDEO_DETAIL_UPDATE_CLIP_REQUEST, updateClipRequestRequest);
}

function* watchUpdateAvailableMediaRequest() {
  yield takeEvery(VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA, updateAvailableMediaRequest);
}

function* watchDeleteClipsRequestRequest() {
  yield takeEvery(VIDEO_DETAIL_DELETE_CLIPS_REQUEST, deleteClipsRequestRequest);
}

function* watchDeleteAvailableMediaRequest() {
  yield takeEvery(VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA, deleteAvailableMediaRequest);
}

function* watchAddNoteAvailableMediaRequest() {
  yield takeEvery(VIDEO_DETAIL_ADD_NOTE, addNoteAvailableMediaRequest);
}

function* watchDeleteNotesAvailableMediaRequest() {
  yield takeEvery(VIDEO_DETAIL_DELETE_NOTES, deleteNotesAvailableMediaRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAvailableMediaRequest),
    fork(watchGetClipRequestRequest),
    fork(watchUpdateClipRequestRequest),
    fork(watchUpdateAvailableMediaRequest),
    fork(watchDeleteClipsRequestRequest),
    fork(watchDeleteAvailableMediaRequest),
    fork(watchAddNoteAvailableMediaRequest),
    fork(watchDeleteNotesAvailableMediaRequest),
  ]);
}