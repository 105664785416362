import {
  INFO_BUBBLE_GET_MARKER_DETAILS,
  INFO_BUBBLE_GET_MARKER_DETAILS_RESPONSE,
  INFO_BUBBLE_GET_LAST_SNAPSHOT,
  INFO_BUBBLE_GET_LAST_SNAPSHOT_RESPONSE,
  INFO_BUBBLE_SHOW_TIMELINE,
  INFO_BUBBLE_CLIP_REQUEST_DATE,
  INFO_BUBBLE_GET_PUBLIC_MARKER_DETAILS,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  loadingGetMarkerDetails: false,
  getMarkerDetailsResponse: null,
  loadingSnapshot: false,
  snapshot: null,
  getMarkerDetailsId: 0,
  showTimeline: false,
  clipRequestDate: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INFO_BUBBLE_GET_MARKER_DETAILS:
      return { ...state, 
                loadingGetMarkerDetails: true, 
                getMarkerDetailsId: action.payload.data.id,
                getMarkerDetailsResponse: state?.getMarkerDetailsId != action.payload.data.id ? null : state.getMarkerDetailsResponse
              };
    case INFO_BUBBLE_GET_PUBLIC_MARKER_DETAILS:
      return {
        ...state,
        loadingGetMarkerDetails: true, 
        getMarkerDetailsId: action.payload,
        getMarkerDetailsResponse: state?.getMarkerDetailsId != action.payload ? null : state.getMarkerDetailsResponse
      }

    case INFO_BUBBLE_GET_MARKER_DETAILS_RESPONSE:
      return { ...state, getMarkerDetailsResponse: action.payload.data, loadingGetMarkerDetails: false };

    case INFO_BUBBLE_GET_LAST_SNAPSHOT:
      return { ...state, snapshot: null, loadingSnapshot: true };

    case INFO_BUBBLE_GET_LAST_SNAPSHOT_RESPONSE:
      return { ...state, snapshot: action.payload.data, loadingSnapshot: false };

    case INFO_BUBBLE_SHOW_TIMELINE:
      return { ...state, showTimeline: action.payload.data };

    case INFO_BUBBLE_CLIP_REQUEST_DATE:
      return { ...state, clipRequestDate: action.payload.data };

    case CLEAR_REDUX:
      return ["", "INFO_BUBBLE"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}